import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './ReportsList.css';
import { Container, TableFooter } from '@mui/material';

const ReportsList = ({ data }) => {
    const credit = data[0].creditNote !== undefined;

    const calculateCreditTotal = (cart) => {
        let total = 0;

        for (let i = 0; i < cart.length; i++) {
            const item = cart[i];
            
            total += item.amount * item.price
        }

        return total;
    }

    const totals = {
        netTotal: 0,
        shippingTotal: 0,
        surchargeTotal: 0,
        vatTotal: 0,
        grossTotal: 0,
      };
      
      if (credit) {
        data.forEach((row) => {
            // const net = calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.shipping + row.creditNoteDetails.surcharge + ((calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.surcharge + row.creditNoteDetails.shipping) / 1.18 * 0.18);
            const net = calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.shipping + row.creditNoteDetails.surcharge;
            const vat = (calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.surcharge + row.creditNoteDetails.shipping) / 1.18 * 0.18;
        
            totals.netTotal += net;
            totals.shippingTotal += row.creditNoteDetails.shipping;
            totals.surchargeTotal += row.creditNoteDetails.surcharge;
            totals.vatTotal += vat;
            totals.grossTotal += calculateCreditTotal(row.creditNoteDetails.items);
        });
      } else {
        data.forEach((row) => {
            const net = row.order.total - row.order.shipping - row.order.surcharge - ((row.order.total + row.order.surcharge + row.order.shipping) / 1.18 * 0.18);
            const vat = (row.order.total + row.order.surcharge + row.order.shipping) / 1.18 * 0.18;
        
            totals.netTotal += net;
            totals.shippingTotal += row.order.shipping;
            totals.surchargeTotal += row.order.surcharge;
            totals.vatTotal += vat;
            totals.grossTotal += row.order.total;
        });
    }

    return (
        <Container maxWidth="lg" className="mt-20">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{credit ? 'Credit Note' : 'Invoice'} #</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Net</TableCell>
                            <TableCell>Shipping</TableCell>
                            <TableCell>Surcharge</TableCell>
                            <TableCell>18% VAT</TableCell>
                            <TableCell>Gross</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {credit ? data.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className="creditnotes-row"
                            >
                                <TableCell component="th" scope="row">
                                    {row.creditNoteDetails.creditNoteNumber}
                                </TableCell>
                                <TableCell>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(row.creditNoteDetails.date))}</TableCell>
                                {/* <TableCell component="th" scope="row">-€{(calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.shipping + row.creditNoteDetails.surcharge + ((calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.surcharge + row.creditNoteDetails.shipping) / 1.18 * 0.18)).toFixed(2)}</TableCell> */}
                                <TableCell component="th" scope="row">-€{(calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.shipping + row.creditNoteDetails.surcharge).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">-€{(row.creditNoteDetails.shipping).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">-€{(row.creditNoteDetails.surcharge).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">-€{((calculateCreditTotal(row.creditNoteDetails.items) + row.creditNoteDetails.surcharge + row.creditNoteDetails.shipping) / 1.18 * 0.18).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">-€{(calculateCreditTotal(row.creditNoteDetails.items)).toFixed(2)}</TableCell>
                                
                            </TableRow>
                        )) : data.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className="creditnotes-row"
                            >
                                <TableCell component="th" scope="row">
                                    {row.invoiceNumber}
                                </TableCell>
                                <TableCell>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(row.date))}</TableCell>
                                <TableCell component="th" scope="row">€{(row.order.total - row.order.shipping - row.order.surcharge - ((row.order.total + row.order.surcharge + row.order.shipping) / 1.18 * 0.18)).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">€{(row.order.shipping).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">€{(row.order.surcharge).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">€{((row.order.total + row.order.surcharge + row.order.shipping) / 1.18 * 0.18).toFixed(2)}</TableCell>
                                <TableCell component="th" scope="row">€{(row.order.total).toFixed(2)}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell></TableCell>
                            <TableCell component="th" scope="row">{credit && '-'}€{totals.netTotal.toFixed(2)}</TableCell>
                            <TableCell component="th" scope="row">{credit && '-'}€{totals.shippingTotal.toFixed(2)}</TableCell>
                            <TableCell component="th" scope="row">{credit && '-'}€{totals.surchargeTotal.toFixed(2)}</TableCell>
                            <TableCell component="th" scope="row">{credit && '-'}€{totals.vatTotal.toFixed(2)}</TableCell>
                            <TableCell component="th" scope="row">{credit && '-'}€{totals.grossTotal.toFixed(2)}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default ReportsList