import Navbar from '../../components/navbar/Navbar';
import './editProduct.css';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const EditProduct = () => {
    const [id, setId] = useState('');
    const [product, setProduct] = useState(null);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('account') == null || localStorage.getItem('admin') != "true") navigate('/login')
    }, []);

    useEffect(() => {
        if (params.id) {
            setId(params.id);
        } else {
            navigate('/');
        }
    }, [params])

    useEffect(() => {
        if (id) {
            fetch(`${process.env.REACT_APP_API}/api/getCustomProduct/${id}`, {})
            .then(response => {
                if (response.status === 200) {
                    response.json().then(data => setProduct(data));
                }
            })
        }
    }, [id])

    const editProduct = (e) => {
        e.preventDefault();
        const name = document.getElementById("name").value;
        const description = document.getElementById("description").value;
        const price = document.getElementById("price").value;
        const dark = document.getElementById("dark").checked;
        const archived = document.getElementById("archived").checked;

        fetch(`${process.env.REACT_APP_API}/api/editProduct/${id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                name: name,
                description: description,
                price: price,
                dark: dark,
                archived: archived,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success(`${id ? 'Updated' : 'Created'} Product!`)
                navigate('/admin')
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    return (
        <div className="admin">
            <Navbar />

            <h1 className="mt-50 page-title">{id ? 'Edit' : 'Create'} Product</h1>

            <div className="admin-wrapper mt-20 mb-50">
                <div className="admin-tools-wrapper">
                    <div className="admin-tools">
                        {product && <form>
                            <h4 className="mt-0 mb-20">Product Info</h4>

                            <div className="input-group">
                                <label>Name</label>
                                <input defaultValue={product.name} name="name" id="name"></input>
                            </div>
                            <div className="input-group">
                                <label>Description</label>
                                <input defaultValue={product.description} name="description" id="description"></input>
                            </div>
                            <div className="input-group">
                                <label>Price</label>
                                <input defaultValue={product.price} name="price" id="price"></input>
                            </div>

                            <div className="input-group">
                                <label>Dark</label>
                                <input type="checkbox" defaultChecked={product.dark} name="dark" id="dark" style={{ width: '20px' }}></input>
                            </div>

                            <div className="input-group">
                                <label>Archived</label>
                                <input type="checkbox" defaultChecked={product.archived} name="archived" id="archived" style={{ width: '20px' }}></input>
                            </div>

                            <button onClick={(e) => editProduct(e)}>Update</button>
                        </form>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditProduct;