import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import Navbar from '../../components/navbar/Navbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    let navigate = useNavigate();

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
    
        fetch(`${process.env.REACT_APP_API}/api/login`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                email: event.target.email.value,
                password: event.target.password.value,
            })
        })
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => localStorage.setItem('admin', data ? data : false));
                localStorage.setItem('account', event.target.email.value);
                toast.success('Logged in successfully!')
                navigate('/');
            } else if (response.status === 401) {
                toast.error('Wrong password!')
            } else if (response.status === 404) {
                toast.error('Account not found.')
            }
        })
    }

    return (
        <div className="login">
            <Navbar />
            
            <div className="login-inner">
                <form onSubmit={handleLoginSubmit}>
                    <div>
                        <h4>Log In</h4>
                    </div>

                    <div className="input-group">
                        <label>Email</label>
                        <input type="email" name="email" id="email"></input>
                    </div>

                    <div className="input-group">
                        <label>Password</label>
                        <input type="password" name="password" id="password"></input>
                    </div>

                    <button>Log In</button>
                </form>
            </div>
        </div>
    );
}

export default Login;