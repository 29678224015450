import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './CreditNotesList.css';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CreditNotesList = ({ data }) => {
    let navigate = useNavigate();

    console.log(data);

    return (
        <Container maxWidth="lg" className="mt-20">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>CreditNote #</TableCell>
                            <TableCell>Order #</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Customer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className="creditnotes-row"
                                onClick={() => navigate(`/creditnote/${row._id}`)}
                            >
                                <TableCell component="th" scope="row">
                                    {row.creditNote.creditNoteNumber}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.orderNumber}
                                </TableCell>
                                <TableCell>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(row.creditNote.date))}</TableCell>
                                <TableCell>
                                    <div className="creditnotes-shipping">
                                        <p>{row.deliveryname}</p>
                                        <p>{row.deliverystreet}</p>
                                        <p>{row.deliverycity}</p>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default CreditNotesList