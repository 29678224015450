import Navbar from '../../components/navbar/Navbar';
import './createProduct.css';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ProductsList from '../../components/ProductsList/ProductsList';
import { FaCheck, FaFolder } from 'react-icons/fa';
import Dropzone from 'react-dropzone';

const CreateProduct = () => {
    const navigate = useNavigate();
    const [arrayBuffer, setArrayBuffer] = useState(undefined);
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('account') == null || localStorage.getItem('admin') != "true") navigate('/login')
    }, []);

    const createProduct = (e) => {
        setUploading(true);

        const name = document.getElementById("name").value;
        const description = document.getElementById("description").value;
        const price = document.getElementById("price").value;
        const dark = document.getElementById("dark").checked;
    
        const formData = new FormData();
        let images = [];

        const bar = new Promise((resolve, reject) => {
            const test = new Blob([arrayBuffer], { type: type });
            images.push(test);
            resolve();
        });

        bar.then(() => {
            images.forEach((image, index) => formData.append(name, image, name))
            formData.append('name', name)
            formData.append('description', description)
            formData.append('price', price)
            formData.append('dark', dark)
            fetch(`${process.env.REACT_APP_API}/api/createProduct`, {
                method: 'POST',
                cache: 'no-cache',
                credentials: 'same-origin',
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: formData
            })
            .then(response => {
                if (response.status === 200) {
                    toast.success('Create Product!')
                    navigate('/admin')
                } else if (response.status === 500) {
                    toast.error('Server error.')
                }
            });

            navigate('/admin')
        });

        e.preventDefault();
    }

    return (
        <div className="admin">
            <Navbar />

            <h1 className="mt-50 page-title">Create Product</h1>

            <div className="admin-wrapper mt-20 mb-50">
                <div className="admin-tools-wrapper">
                    <div className="admin-tools">
                        <form>
                            <h4 className="mt-0 mb-20">Product Info</h4>

                            <div className="input-group">
                                <label>Name</label>
                                <input name="name" id="name"></input>
                            </div>
                            <div className="input-group">
                                <label>Description</label>
                                <input name="description" id="description"></input>
                            </div>
                            <div className="input-group">
                                <label>Price</label>
                                <input name="price" id="price"></input>
                            </div>
                            <div className="input-group">
                                <label>Dark</label>
                                <input type="checkbox" name="dark" id="dark" style={{ width: '20px' }}></input>
                            </div>
                            <div className="input-group" style={{ height: '150px' }}>
                                <label>Img</label>
                                {!arrayBuffer  && !uploading && <Dropzone onDrop={acceptedFiles => {
                                    const reader = new FileReader();
                                    reader.onload = () => {
                                        const binaryStr = reader.result
                                        console.log(acceptedFiles[0])
                                        setArrayBuffer(binaryStr)
                                        setType(acceptedFiles[0].type)
                                        setName(acceptedFiles[0].name)
                                    }
                                    reader.readAsArrayBuffer(acceptedFiles[0])
                                }}>
                                    {({getRootProps, getInputProps}) => (
                                    <section className="cloneDropzone">
                                        <div {...getRootProps()} className='cloneInnerDropzone'>
                                        <FaFolder color="#8CBD22" size="64px"/>
                                        <input {...getInputProps()} />
                                        <p>Drag a file or click here...</p>
                                        </div>
                                    </section>
                                    )}
                                </Dropzone>}
                                {arrayBuffer && !uploading && <section className="cloneDropzone">
                                        <div className='cloneInnerDropzone'>
                                        <FaCheck color="#8CBD22" size="64px"/>
                                        <p>Uploaded!</p>
                                        </div>
                                </section>}
                            </div>

                            <button style={{ marginTop: '50px' }} onClick={(e) => createProduct(e)}>Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateProduct;