import Navbar from '../../components/navbar/Navbar';
import './Invoices.css';
import OrdersList from '../../components/OrdersList/OrdersList';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState("");
    const [filter, setFilter] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        getInvoices(1);
    }, []);

    const getInvoices = (index) => {
        let url = `${process.env.REACT_APP_API}/api/get${(localStorage.getItem('admin') == "true") ? 'AllInvoices' : ('Invoices/' + localStorage.getItem('account'))}`;
        if (query.length > 0) url += ('?query=' + query)
        if (filter.length > 0) url += (query.length > 0 ? '&' : '?') + ('filter=' + filter)
        url += ((query.length > 0 || filter.length > 0) ? '&' : '?') + ('page=' + index)

        fetch(url, {})
        .then(response => {
            if (response.status === 200) {
                if (localStorage.getItem('account') !== null) {
                    response.json().then(data => setInvoices(data.filter(x => x.status === "APPROVED")));
                } else {
                    navigate('/')
                }
            }
        })
    }

    const changeQuery = (e) => {
        setQuery(e.target.value);
    }

    const changeFilter = (e) => {
        setFilter(e.target.value);
    }

    const handlePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            getInvoices(page - 1);
        }
    }

    const handleNext = () => {
        setPage(page + 1);
        getInvoices(page + 1);
    }

    return (
        <div className="invoices mb-50">
            <Navbar />
            
            <h1 className="mt-50 mb-30 page-title">Invoices</h1>
            {(localStorage.getItem('admin') == "true") && <Container maxWidth="lg" className="mt-20 invoices-filters">
                <div style={{ display: 'flex' }}>
                    <div className="input-group">
                        <label>Search</label>
                        <input type="text" value={query} onChange={changeQuery}></input>
                    </div>
                    <div className="input-group" style={{ marginLeft: '20px'}}>
                        <label>Status</label>
                        <select value={filter} onChange={changeFilter}>
                            <option value="">ALL</option>
                            <option value="SUBMITTED">SUBMITTED</option>
                            <option value="APPROVED">APPROVED</option>
                            <option value="DECLINED">DECLINED</option>
                            <option value="SHIPPED">SHIPPED</option>
                        </select>
                    </div>
                </div>
                <div className="search-button-wrapper">
                    <button onClick={() => getInvoices(page)}>Search</button>
                    <button onClick={() => navigate('/createcreditnote')} style={{ marginLeft: '20px' }}>Create Credit Note</button>
                </div>
            </Container>}
            {(localStorage.getItem('admin') == "true") && <div style={{ display: 'flex' }}>
                <button onClick={handlePrev}>Prev</button>
                <p style={{ margin: '0px 10px' }}>{page}</p>
                <button onClick={handleNext}>Next</button>
                </div>}
            {invoices.length > 0 && <OrdersList data={invoices} invoices={true} />}
        </div>
    );
}

export default Invoices;