import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import './Xero.css';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const Xero = () => {
    const navigate = useNavigate();

    useEffect(() => {
        auth();
    })

    const auth = () => {
        fetch(`${process.env.REACT_APP_API}/api/getTokenSet${window.location.href.split("url=")[1]}`, {})
        .then(response => response.json())
        .then (data => {
            toast.success("Succesfully Authenticated")
            navigate('/admin')
        })
    }

    return (
        <div className="order-detail mb-50">
            <Navbar />
        </div>
    );
}

export default Xero;