import { useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import './Customer.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import OrdersList from '../../components/OrdersList/OrdersList';

const Customer = () => {
    const [customer, setCustomer] = useState(null);
    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        if (params.id) {
            getCustomer(params.id);
        } else {
            navigate('/');
        }
    }, [params])

    const getCustomer = (id) => {
        fetch(`${process.env.REACT_APP_API}/api/getCustomer/${id}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => setCustomer(data));
            }
        })
    }

    return (
        <div className="customer">
            <Navbar />
            
            <div className="customer-wrapper">
                {customer && <h1 className="mt-50">{customer.name}</h1>}
                <div className="customer-info">
                    {customer && <div className="customer-info-card">
                        <label>VAT: </label>
                        <p>{customer.VAT}</p>
                    </div>}
                </div>
                {customer && customer.orders.length > 0 && <OrdersList data={customer.orders} />}
            </div>
        </div>
    );
}

export default Customer;