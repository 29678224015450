import Navbar from '../../components/navbar/Navbar';
import './Account.css';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Account = () => {
    const [account, setAccount] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        getAccount();
    }, []);


    const getAccount = () => {
        if (localStorage.getItem('account') == null) navigate('/login')
        fetch(`${process.env.REACT_APP_API}/api/getAccount/${localStorage.getItem('account')}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setAccount(data);
                });
            }
        })
    }

    const updateAccount = (e) => {
        e.preventDefault();
        const name = document.getElementById("name").value;
        const campanyName = document.getElementById("campanyName").value;
        const VAT = document.getElementById("VAT").value;
        const street = document.getElementById("street").value;
        const city = document.getElementById("city").value;
        const postCode = document.getElementById("postCode").value;
        const phone = document.getElementById("phone").value;
        const email = document.getElementById("email").value;

        if (name === "") {
            toast.error('Name is required.');
            return;
        }
        if (street === "") {
            toast.error('Street is required.');
            return;
        }
        if (city === "") {
            toast.error('City is required.');
            return;
        }
        if (postCode === "") {
            toast.error('Post Code is required.');
            return;
        }
        if (phone === "") {
            toast.error('Phone is required.');
            return;
        }
        if (email === "") {
            toast.error('Email is required.');
            return;
        }

        fetch(`${process.env.REACT_APP_API}/api/updateAccount/${account._id}`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                name: name,
                campanyName: campanyName,
                VAT: VAT,
                street: street,
                city: city,
                postCode: postCode,
                phone: phone,
                email: email
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Updated account!')
            } else if (response.status === 500) {
                response.json().then(data => {
                    toast.error(data)
                })
            }
        });
    }

    const updatePassword = (e) => {
        e.preventDefault();
        const password = document.getElementById("password").value;
        const confirmpassword = document.getElementById("confirmpassword").value;

        if (password === "") {
            toast.error('Password is required.');
            return;
        }
        if (confirmpassword !== password) {
            toast.error('Passwords do not match.');
            return;
        }

        fetch(`${process.env.REACT_APP_API}/api/updatePassword/${account._id}`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                password: password,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed password!')
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    const deleteAccount = (e) => {
        e.preventDefault();

        fetch(`${process.env.REACT_APP_API}/api/removeAccount`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                id: account._id,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Deleted account!');
                navigate('/');
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }

    return (
        <div className="account">
            <Navbar />

            <h1 className="mt-50 page-title">Account</h1>

            <div className="account-info-wrapper mt-20 mb-50">
                <div className="account-info">
                    {account && <form>
                        <h4 className="mt-0 mb-20">Billing Details</h4>

                        <div className="input-group">
                            <label>Full Name</label>
                            <input type="text" name="name" defaultValue={account.name} id="name"></input>
                        </div>

                        <div className="input-group">
                            <label>Company Name</label>
                            <input type="text" name="campanyName" defaultValue={account.campany} id="campanyName"></input>
                        </div>

                        <div className="input-group">
                            <label>VAT NO</label>
                            <input type="text" name="VAT" defaultValue={account.VAT} id="VAT"></input>
                        </div>

                        <div className="input-group">
                            <label>Street Address</label>
                            <input type="text" name="street" defaultValue={account.street} id="street"></input>
                        </div>

                        <div className="input-group">
                            <label>Town / City</label>
                            <input type="text" name="city" defaultValue={account.city} id="city"></input>
                        </div>

                        <div className="input-group">
                            <label>Post Code</label>
                            <input type="text" name="postCode" defaultValue={account.postCode} id="postCode"></input>
                        </div>

                        <div className="input-group">
                            <label>Phone</label>
                            <input type="tel" name="phone" defaultValue={account.phone} id="phone"></input>
                        </div>

                        <div className="input-group">
                            <label>Email</label>
                            <input type="email" name="email" defaultValue={account.email} id="email"></input>
                        </div>

                        <button onClick={(e) => updateAccount(e)}>Update</button>
                    </form>}
                </div>
                <div className="account-tools-wrapper">
                    <div className="account-tools">
                        <form>
                            <h4 className="mt-0 mb-20">Change Password</h4>

                            <div className="input-group">
                                <label>Password</label>
                                <input type="password" name="password" id="password"></input>
                            </div>

                            <div className="input-group">
                                <label>Confirm Password</label>
                                <input type="password" name="confirmpassword" id="confirmpassword"></input>
                            </div>

                            <button onClick={(e) => updatePassword(e)}>Change</button>
                        </form>
                    </div>

                    {/* <div className="account-tools mt-50">
                        <form>
                            <h4 className="mt-0 mb-20">Remove Account</h4>
                            <p>Click the button below if you want to delete your account from our database.</p>

                            <button className="delete" onClick={(e) => deleteAccount(e)}>Remove</button>
                        </form>
                    </div> */}
                    
                    <p className='mt-50'>Click here if you want to log out.</p>
                    <button className="delete" onClick={() => {localStorage.removeItem('account'); localStorage.removeItem('admin'); location.reload()}}>LOG OUT</button>
                </div>
            </div>
        </div>
    );
}

export default Account;