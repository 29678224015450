import React from 'react';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
// import reportWebVitals from './reportWebVitals';
import store from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

let persistor = persistStore(store);
import { Provider } from 'react-redux'

import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import NoMatch from './pages/nomatch/NoMatch';
import Users from './pages/users/Users';
import Product from './pages/product/Product';
import Cart from './pages/cart/Cart';
import Order from './pages/order/Order';
import Orders from './pages/orders/Orders';
import OrderDetail from './pages/orderDetail/OrderDetail';
import Customer from './pages/customer/Customer';
import Account from './pages/account/Account';
import Privacy from './pages/privacy/Privacy';
import Terms from './pages/terms/Terms';
import Admin from './pages/admin/Admin';
import Contact from './pages/contact/Contact';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from './utils/ScrollToTop';
import Xero from './pages/xero/Xero';
import InvoiceDetail from './pages/invoiceDetail/InvoiceDetail';
import Payment from './pages/payment/Payment';
import Invoices from './pages/invoices/Invoices';
import CreditNotes from './pages/creditnotes/CreditNotes';
import Reports from './pages/reports/Reports';
import CreditNoteDetail from './pages/CreditNoteDetail/CreditNoteDetail';
import CreateCreditNote from './pages/createCreditNote/createCreditNote';
import Bricoman from './pages/bricoman/Bricoman';
import CreateProduct from './pages/createProduct/createProduct';
import EditProduct from './pages/editProduct/editProduct';

const rootElement = document.getElementById("root");
render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastContainer
       autoClose={500}
      />
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="register" element={<Register />} />
          <Route path="users" element={<Users />} />
          <Route path="orders" element={<Orders />} />
          <Route path="creditnotes" element={<CreditNotes />} />
          <Route path="reports" element={<Reports />} />
          <Route path="invoices" element={<Invoices />} />
          <Route path="cart" element={<Cart />} />
          <Route path="order" element={<Order />} />
          <Route path="account" element={<Account />} />
          <Route path="contact" element={<Contact />} />
          <Route path="admin" element={<Admin />} />
          <Route path="admin/createProduct" element={<CreateProduct />} />
          <Route path="admin/editProduct/:id" element={<EditProduct />} />
          <Route path="bricoman" element={<Bricoman />} />
          <Route path="xero/:url" element={<Xero />} />
          <Route path="customer/:id" element={<Customer />} />
          <Route path="createcreditnote" element={<CreateCreditNote />} />
          <Route path="createcreditnote/:id" element={<CreateCreditNote />} />
          <Route path="creditnote/:id" element={<CreditNoteDetail />} />
          <Route path="order/:id" element={<OrderDetail />} />
          <Route path="invoice/:id" element={<InvoiceDetail />} />
          <Route path="product/:id" element={<Product />} />
          <Route path="payment/:status" element={<Payment />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
