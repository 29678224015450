import Navbar from '../../components/navbar/Navbar';
import './Bricoman.css';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Bricoman = () => {
    const navigate = useNavigate();

    const order = (e) => {
        e.preventDefault();
        const name = document.getElementById("name").value;
        const email = document.getElementById("email").value;
        const order = document.getElementById("order").value;

        if (!name || name.length < 1) {
            toast.error('Please enter your name')
            return;
        }

        if (!email || email.length < 1) {
            toast.error('Please enter your email')
            return;
        }

        fetch(`${process.env.REACT_APP_API}/api/bricoman`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                name: name,
                email: email,
                order: order,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Ordered!')
                navigate('/')
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    return (
        <div className="bricoman">
            <Navbar />

            <h1 className="mt-50 page-title">Bricoman</h1>

            <div className="bricoman-wrapper mt-20 mb-50">
                <div className="bricoman-tools-wrapper">
                    <div className="bricoman-tools">
                        <form>
                            <h4 className="mt-0 mb-20">Bricoman Order</h4>

                            <div className="input-group">
                                <label>Name</label>
                                <input placeholder="John Doe" name="name" id="name"></input>
                            </div>

                            <div className="input-group">
                                <label>Email</label>
                                <input placeholder="johndoe@gmail.com" name="email" id="email"></input>
                            </div>

                            <div className="input-group" style={{ height: "130px" }}>
                                <label>Order</label>
                                <textarea defaultValue="1x 10019641" name="order" id="order"></textarea>
                            </div>

                            <button onClick={(e) => order(e)}>Order</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bricoman;