import './PrintHero.css';

const PrintHero = () => {
    return (
        <div className="printhero">
            <div className="printhero-wrapper">
                <div className="printhero-item">
                    <img src="../../../logo_new.png" />
                </div>
                <div className="printhero-item">
                    <p>Sicily To Malta Ltd</p>
                    <p>Warehouse E 13</p>
                    <p>Midland Park,</p>
                    <p>Industrial Estate Naxxar</p>
                    <p>NXR 6345</p>
                    <p>Malta</p>
                </div>
                <div className="printhero-item">
                    <p>Tel: 99456742/77779991</p>
                    <p>Vat: MT30397804</p>
                </div>
            </div>
        </div>
    );
};

export default PrintHero