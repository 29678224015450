import './Privacy.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

const Privacy = () => {
    return (
        <div className="privacy">
            <Navbar />
            
            <div className="privacy-container">
                <h3 className="mt-50"><strong>Privacy Policy:</strong></h3>
                <p>&nbsp;</p>
                <p>Sicily Shopping to Malta understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website, sicilyshoppingtomalta.com (“Our Site”) and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.</p>
                <p>&nbsp;</p>
                <p>Please read this Privacy Policy carefully and ensure that you understand it. Your acceptance of this Privacy Policy is deemed to occur upon your first use of Our Site AND you will be required to read and accept this Privacy Policy when signing up for an Account. If you do not accept and agree with this Privacy Policy, you must stop using Our Site immediately.</p>
                <p>&nbsp;</p>
                <ol>
                <li>
                <h4>Definitions and Interpretation</h4>
                </li>
                </ol>
                <p>In this Policy the following terms shall have the following meanings:</p>
                <p>&nbsp;</p>
                <table>
                <tbody>
                <tr>
                <td width="145"><strong>“Account”</strong></td>
                <td width="271">
                <p>means an account required to access and/or use certain areas and features of Our Site;</p>
                </td>
                </tr>
                <tr>
                <td width="145"><strong>“Cookie”</strong></td>
                <td width="271">
                <p>means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in Part 14, below; and</p>
                </td>
                </tr>
                <tr>
                <td width="145"><strong>“Cookie Law”</strong></td>
                <td width="271">
                <p>means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003;</p>
                </td>
                </tr>
                </tbody>
                </table>
                <p>&nbsp;</p>
                <ol start="2">
                <li>
                <h4>Information About Us</h4>
                </li>
                </ol>
                <p>Our Site is owned and operated by Sicily Shopping to Malta, a brand operated by Ronald Gauci holding ID Card Number 491673M.</p>
                <p>Registered address: Occas Flats, 3, Triq San Publiju, Naxxar, Malta.</p>
                <p>Email address: info@sicilyshoppingtomalta.com</p>
                <p>Telephone number: 0035699456742</p>
                <p>Postal address: Occas Flats, 3, Triq San Publiju, Naxxar</p>
                <p>Representative: Ronald Gauci</p>
                <p>&nbsp;</p>
                <ol start="3">
                <li>
                <h4>What Does This Policy Cover?</h4>
                </li>
                </ol>
                <p>This Privacy Policy applies only to your use of Our Site. Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>
                <p>&nbsp;</p>
                <ol start="4">
                <li>
                <h4>What Is Personal Data?</h4>
                </li>
                </ol>
                <p>Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018 (collectively, “the Data Protection Legislation”) as ‘any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier’.</p>
                <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>
                <p>&nbsp;</p>
                <ol start="5">
                <li>
                <h4>What Are My Rights?</h4>
                </li>
                </ol>
                <p>Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:</p>
                <ol start="15">
                <li>The right to be informed about our collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions using the details in Part 15.</li>
                <li>The right to access the personal data we hold about you. Part 13 will tell you how to do this.</li>
                <li>The right to have your personal data rectified if any of your personal data held by us is inaccurate or incomplete. Please contact us using the details in Part 15 to find out more.</li>
                <li>The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your personal data that we Please contact us using the details in Part 15 to find out more.</li>
                <li>The right to restrict (i.e. prevent) the processing of your personal data.</li>
                <li>The right to object to us using your personal data for a particular purpose or purposes.</li>
                <li>The right to withdraw consent. This means that, if we are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.</li>
                <li>The right to data portability. This means that, if you have provided personal data to us directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.</li>
                <li>Rights relating to automated decision-making and profiling. We do not use your personal data in this way.</li>
                </ol>
                <p>For more information about our use of your personal data or exercising your rights as outlined above, please contact us using the details provided in Part 15.</p>
                <p>It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.</p>
                <p>Further information about your rights can also be obtained from the Information Commissioner’s Office.</p>
                <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve your concerns ourselves, however, so please contact us first, using the details in Part 15.</p>
                <p>&nbsp;</p>
                <ol start="6">
                <li>
                <h4>What Data Do You Collect and How?</h4>
                </li>
                </ol>
                <p>Depending upon your use of Our Site, we may collect and hold some or all of the personal data set out in the table below, using the methods also set out in the table. Please also see Part 14 for more information about our use of Cookies and similar technologies.</p>
                <p><strong> </strong></p>
                <table>
                <tbody>
                <tr>
                <td width="227">
                <h4><strong>Data Collected</strong></h4>
                </td>
                <td width="226">
                <h4><strong>How We Collect the Data</strong></h4>
                </td>
                </tr>
                <tr>
                <td width="227">Identity Information including name and surname;</td>
                <td width="226">Through the account section in the website.</td>
                </tr>
                <tr>
                <td width="227">Contact information including address, telephone number and email address;</td>
                <td width="226">Through the account section in the website.</td>
                </tr>
                </tbody>
                </table>
                <ol start="7">
                <li>
                <h3>How Do You Use My Personal Data?</h3>
                </li>
                </ol>
                <p>Under the Data Protection Legislation, we must always have a lawful basis for using personal data. The following table describes how we may use your personal data, and our lawful bases for doing so:</p>
                <p>&nbsp;</p>
                <table>
                <tbody>
                <tr>
                <td width="152">
                <h4><strong>What We Do</strong></h4>
                </td>
                <td width="151">
                <h4><strong>What Data We Use</strong></h4>
                </td>
                <td width="151">
                <h4><strong>Our Lawful Basis</strong></h4>
                </td>
                </tr>
                <tr>
                <td width="152">Providing and managing your Account.</td>
                <td width="151">Name and surname, address, email address and telephone number;</td>
                <td width="151">We need this information to be able to manage your orders;</td>
                </tr>
                <tr>
                <td width="152">Supplying the products to you.</td>
                <td width="151">Name and surname, address, email address and telephone number;</td>
                <td width="151">We need this information to be able to deliver your ordered products;</td>
                </tr>
                <tr>
                <td width="152">Communicating with you.</td>
                <td width="151">Name and surname, address, email address and telephone number;</td>
                <td width="151">We need this information to be able to call you when delivering your order;</td>
                </tr>
                </tbody>
                </table>
                <p>&nbsp;</p>
                <p>With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by email OR telephone OR text message OR post with information, news, and offers on the products. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt-out. We will always obtain your express opt-in consent before sharing your personal data with third parties for marketing purposes and you will be able to opt-out at any time.</p>
                <p>We will only use your personal data for the purpose(s) for which it was originally collected unless we reasonably believe that another purpose is compatible with that or those original purpose(s) and need to use your personal data for that purpose. If we do use your personal data in this way and you wish us to explain how the new purpose is compatible with the original, please contact us using the details in Part 15.</p>
                <p>If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the purpose(s) for which it was originally collected, we will inform you and explain the legal basis which allows us to do so.</p>
                <p>In some circumstances, where permitted or required by law, we may process your personal data without your knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your legal rights.</p>
                <p>&nbsp;</p>
                <ol start="8">
                <li>
                <h4>How Long Will You Keep My Personal Data?</h4>
                </li>
                </ol>
                <p>We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected. Your personal data will therefore be kept for the following periods (or, where there is no fixed period, the following factors will be used to determine how long it is kept):</p>
                <p>&nbsp;</p>
                <table>
                <tbody>
                <tr>
                <td width="227"><strong>Type of Data</strong></td>
                <td width="226"><strong>How Long We Keep It</strong></td>
                </tr>
                <tr>
                <td width="227">Identity Information including name and surname.</td>
                <td width="226">You are free to delete your account from the website at which point we will delete your information except as stored on the orders.</td>
                </tr>
                <tr>
                <td width="227">Contact information including address, telephone number and email address.</td>
                <td width="226">You are free to delete your account from the website at which point we will delete your information except as stored on the orders.</td>
                </tr>
                </tbody>
                </table>
                <p>&nbsp;</p>
                <ol start="9">
                <li>
                <h4>How and Where Do You Store or Transfer My Personal Data?</h4>
                </li>
                </ol>
                <p>We will only store or transfer some of your personal data within the European Economic Area (the “EEA”). The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein. This means that your personal data will be fully protected under the Data Protection Legislation, GDPR, and/or to equivalent standards by law.</p>
                <p>&nbsp;</p>
                <p>The security of your personal data is essential to us, and to protect your data, we take a number of important measures, including the following:</p>
                <ul>
                <li>limiting access to your personal data to those employees, agents, contractors, and other third parties with a legitimate need to know and ensuring that they are subject to duties of confidentiality;</li>
                <li>procedures for dealing with data breaches (the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, your personal data) including notifying you and/or the Information Commissioner’s Office where we are legally required to do so;</li>
                </ul>
                <p>&nbsp;</p>
                <ol start="10">
                <li>
                <h4>Do You Share My Personal Data?</h4>
                </li>
                </ol>
                <p>We will not share any of your personal data with any third parties for any purposes, subject to the following exception<strong>[</strong>s<strong>]</strong>.</p>
                <p>If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</p>
                <p>In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
                <p>&nbsp;</p>
                <p>If any of your personal data is shared with a third party, as described above, we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third party’s obligations under the law, as described above in Part 9.</p>
                <p>If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.</p>
                <p>In some limited circumstances, we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
                <ol start="11">
                <li>
                <h4>How Can I Control My Personal Data?</h4>
                <ul>
                <li>In addition to your rights under the Data Protection Legislation, set out in Part 5, when you submit personal data via Our Site, you may be given options to restrict our use of your personal data. In particular, we aim to give you strong controls on our use of your data for direct marketing purposes (including the ability to opt-out of receiving emails from us which you may do by unsubscribing using the links at the point of providing your details and by managing your Account).</li>
                </ul>
                </li>
                </ol>
                <p>&nbsp;</p>
                <ol start="12">
                <li>
                <h4>Can I Withhold Information?</h4>
                </li>
                </ol>
                <p>You may access Our Site without providing any personal data at all. However, to use all features and functions available on Our Site you may be required to submit or allow for the collection of certain data.</p>
                <p>You may restrict our use of Cookies. For more information, see Part 14.</p>
                <p>&nbsp;</p>
                <ol start="13">
                <li>
                <h4>How Can I Access My Personal Data?</h4>
                </li>
                </ol>
                <p>If you want to know what personal data we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.</p>
                <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in Part 15.</p>
                <p>There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.</p>
                <p>We will respond to your subject access request within a month of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request. You will be kept fully informed of our progress.</p>
                <p>&nbsp;</p>
                <ol start="14">
                <li>
                <h4>How Do You Use Cookies?</h4>
                </li>
                </ol>
                <p>Our Site may place and access certain first-party Cookies on your computer or device. First-party Cookies are those placed directly by us and are used only by us<strong>. </strong>We use Cookies to facilitate and improve your experience of Our Site and to provide and improve our products. We have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times.</p>
                <p>By using Our Site, you may also receive certain third-party Cookies on your computer or device. Third-party Cookies are those placed by websites, services, and/or parties other than us. For more details, please refer to the table below. These Cookies are not integral to the functioning of Our Site and your use and experience of Our Site will not be impaired by refusing consent to them.</p>
                <p>All Cookies used by and on Our Site are used in accordance with current Cookie Law.</p>
                <p>Before Cookies are placed on your computer or device, you will be shown a prompt, eg pop-up requesting your consent to set those Cookies. By giving your consent to the placing of Cookies you are enabling us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain features of Our Site may not function fully or as intended.</p>
                <p>Certain features of Our Site depend on Cookies to function. Cookie Law deems these Cookies to be “strictly necessary”. These Cookies are shown in the table below. Your consent will not be sought to place these Cookies, but it is still important that you are aware of them. You may still block these Cookies by changing your internet browser’s settings as detailed below, but please be aware that Our Site may not work properly if you do so. We have taken great care to ensure that your privacy is not at risk by allowing them.</p>
                <p>The following first-party Cookies may be placed on your computer or device:</p>
                <p>&nbsp;</p>
                <table>
                <tbody>
                <tr>
                <td width="117">Name of Cookie</td>
                <td width="227">Purpose</td>
                <td width="110">Strictly Necessary</td>
                </tr>
                <tr>
                <td width="117">Registration</td>
                <td width="227">For the website to remember that client is logged in</td>
                <td width="110">Yes</td>
                </tr>
                <tr>
                <td width="117">Shopping cart</td>
                <td width="227">For the website to remember the goods added to the cart</td>
                <td width="110">Yes</td>
                </tr>
                </tbody>
                </table>
                <p>&nbsp;</p>
                <p>and the following third-party Cookies may be placed on your computer or device:</p>
                <p>&nbsp;</p>
                <table>
                <tbody>
                <tr>
                <td width="116">Name of Cookie</td>
                <td width="185">Purpose</td>
                </tr>
                <tr>
                <td width="116">Cookie notice consent</td>
                <td width="185">To remember consent</td>
                </tr>
                </tbody>
                </table>
                <p>&nbsp;</p>
                <p>In addition to the controls that we provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all Cookies or only third-party Cookies. By default, most internet browsers accept Cookies, but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.</p>
                <p>You can choose to delete Cookies on your computer or device at any time, however you may lose any information that enables you to access Our Site more quickly and efficiently including, but not limited to, login and personalisation settings.</p>
                <p>It is recommended that you keep your internet browser and operating system up-to-date and that you consult the help and guidance provided by the developer of your internet browser and manufacturer of your computer or device if you are unsure about adjusting your privacy settings.</p>
                <p>&nbsp;</p>
                <ol start="15">
                <li>
                <h4>How Do I Contact You?</h4>
                </li>
                </ol>
                <p>To contact us about anything to do with your personal data and data protection, including to make a subject access request, please use the following details (for the attention of Ronald Gauci):</p>
                <p>Email address: info@sicilyshoppingtomalta.com</p>
                <p>Telephone number: 0035699456742</p>
                <p>Postal Address: Occas Flats, 3, Triq San Publiju, Naxxar.</p>
                <p>&nbsp;</p>
                <ol start="16">
                <li>
                <h4>Changes to this Privacy Policy</h4>
                </li>
                </ol>
                <p>We may change this Privacy Notice from time to time. This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.</p>
                <p className="mb-50">Any changes will be immediately posted on Our Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of Our Site following the alterations. We recommend that you check this page regularly to keep up-to-date. This Privacy Policy was last updated on 7<sup>th</sup> October 2019.</p>
            </div>

            <Footer />
        </div>
    );
}

export default Privacy;