import './Terms.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

const Terms = () => {
    return (
        <div className="terms">
            <Navbar />
            
            <div className="terms-container">
                <h3 className="mt-50"><strong>Terms &amp; Conditions</strong></h3>
                <ol>
                <li>You undertake that all details you provide to Sicily Shopping To Malta for the purpose of ordering or purchasing goods or services are correct.</li>
                <li> If there are any changes to the details supplied by you regarding delivery, it is your responsibility to inform Sicily Shopping To Malta at earliest time.</li>
                <li>Sicily Shopping To Malta is not a local branch of any other entity. Sicily Shopping To Malta is not related in any manner whatsoever to IKEA. We also have no control over their stocks and prices.</li>
                <li>All personal information will not be used outside of Sicily Shopping To Malta.</li>
                <li>By confirming and paying for an order , you are creating a contract of sale between yourself and Sicily Shopping To Malta.</li>
                <li>We have no minimum order amount, but orders which are valued less than €100 are subject to a 10 euros surcharge plus shipping rate to be delivered to your address or else they can be picked up from our warehouse at the Midland Complex in L/O Naxxar and no surcharge will be paid</li>
                <li>
                <div>Any missing or Damaged items have to be notified to us by email in 3 days time from delivery day by photos in case of damaged items and also any damages have to be kept in their packaging and not installed to be able to exchange them for you for no extra cost.</div>
                </li>
                <li>If any ordered item is out of stock at the time of purchase, we will inform you before doing the delivery. You can choose to wait until it is available or else a refund will be given.</li>
                <li>We will not be able to know when the product will be back in stock and therefore it is up to you to wait until the item is available again.</li>
                <li>It is possible that certain IKEA items will need to be ordered from the Piacenza, IKEA warehouse and thus will take a few weeks to be brought to Malta, plus an extra charge may apply.</li>
                <li>Your order will <b>NOT</b> be confirmed until your payment has been submitted and received.</li>
                <li>When making a payment via bank transfer, kindly inform us so that we can confirm that the payment has been received.</li>
                <li>When you receive a wish list from us you are responsible to check that the items written down are the ones you want.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Payments </strong></p>
                <p><strong>Account Holder: </strong> SICILY SHOPPING TO MALTA LTD, TRIQ BURMARRAD MIDLANDS MICRO ENTERPRISE PARK WAREHOUSE E 13, IN-NAXXAR NXR 6345</p>
                <div>
                <div>
                <p>Occas Flats ,No. 3 Triq San Publju, Naxxar, NXR 1423, Malta</p>
                <p><strong>Viva Wallet (Viva Payment Services)<br />
                IBAN</strong> GR4970100003930819024694448<br />
                <strong>BIC</strong> VPAYMTM2 DETAILS Bank ID Branch ID Account No Check digit VPAY 45001 393081902469444841  </p>
                </div>
                <div></div>
                </div>
                <p><b>Deliveries</b></p>
                <ol>
                <li>All deliveries are delivered to the ground floor level, unless there is a working elevator which can be used to carry the items up or else you need to bring a lifter which we can provide for you at a cost depending on the height you have and make a permit for it and parking space at the local council which need to be fixed 48 hours before we do the delivery.</li>
                <li>All clients will be informed once their goods arrive in Malta and a delivery date will be scheduled for the earliest possible date.</li>
                <li>Orders under €100 will be charged an additional home delivery fee of €10.</li>
                </ol>
                <p>&nbsp;</p>
                <p><b>Refunds &amp; Returns</b></p>
                <ol className="mb-50">
                <li>Items sealed in their original box can be returned for a period up to 30 days, but a charge will apply.</li>
                <li>Material/Textiles sold by the meter and real plants cannot be returned.</li>
                </ol>
            </div>

            <Footer />
        </div>
    );
}

export default Terms;