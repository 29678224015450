import { toast } from 'react-toastify';
import Navbar from '../../components/navbar/Navbar';
import './Contact.css';

const Contact = () => {
    const sendContactEmail = (e) => {
        e.preventDefault();

        const firstName = document.getElementById("firstName").value;
        const lastName = document.getElementById("lastName").value;
        const email = document.getElementById("email").value;
        const message = document.getElementById("message").value;

        if (firstName === "") {
            toast.error('First name is required.');
            return;
        }
        if (lastName === "") {
            toast.error('Last name is required.');
            return;
        }
        if (email === "") {
            toast.error('Email is required.');
            return;
        }
        if (message === "") {
            toast.error('Message is required.');
            return;
        }

        fetch(`${process.env.REACT_APP_API}/api/sendContactEmail`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                name: firstName + ' ' + lastName,
                email: email,
                message: message
            })
        })
        .then(response => {
            if (response.status === 200) {
                document.getElementById("firstName").value = "";
                document.getElementById("lastName").value = "";
                document.getElementById("email").value = "";
                document.getElementById("message").value = "";

                toast.success('Sent email!')
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    return (
        <div className="contact">
            <Navbar />

            <h1 className="mt-50 page-title">Contact</h1>

            <div className="contact-info-wrapper mt-20 mb-50">
                <div className="contact-info">
                    <form>
                        <h4 className="mt-0 mb-20">Personal Information</h4>

                        <div style={{ display: 'flex'}}>
                            <div className="input-group" style={{ marginRight: '20px' }}>
                                <label>First Name</label>
                                <input type="text" name="firstName" id="firstName"></input>
                            </div>

                            <div className="input-group" style={{ marginLeft: '20px' }}>
                                <label>Last Name</label>
                                <input type="text" name="lastName" id="lastName"></input>
                            </div>
                        </div>

                        <div className="input-group">
                            <label>Email</label>
                            <input type="email" name="email" id="email"></input>
                        </div>

                        <div className="input-group message">
                            <label>Message</label>
                            <textarea name="message" id="message"></textarea>
                        </div>

                        <button onClick={(e) => sendContactEmail(e)}>Send</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;