import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    add: (state, action) => {
        if (state.items.filter(x => x.id === action.payload.id).length > 0) {
            state.items = state.items.map(x => {
                if (x.id === action.payload.id) {
                    return {
                        ...x,
                        amount: Number(x.amount) + Number(action.payload.amount)
                    };
                }
                return x;
            });
        } else {
            state.items.push({
                id: action.payload.id,
                name: action.payload.name,
                price: action.payload.price,
                img: action.payload.img,
                amount: action.payload.amount,
            })
        }
    },
    remove: (state, action) => {
        state.items = state.items.map(x => {
            if (x.id === action.payload.id) {
                if (x.amount > 1) {
                    return {
                        ...x,
                        amount: x.amount - 1
                    };
                }
            } else {
                return x;
            }
        });
    },
    removeFromCart: (state, action) => {
        state.items = state.items.filter(x => x.id !== action.payload.id)
    },
    setAmount: (state, action) => {
        state.items = state.items.map(x => {
            if (x.id === action.payload.id) {
                return {
                    ...x,
                    amount: action.payload.amount
                };
            } else {
                return x;
            }
        });
    },
    clearCart: (state) => {
        state.items = [];
    }
  },
})

// Action creators are generated for each case reducer function
export const { add, remove, removeFromCart, setAmount, clearCart } = cartSlice.actions

export default cartSlice.reducer