import { useEffect } from 'react';
import './Popup.css';

const Popup = ({ popup, setPopup }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('body').classList.add('modal-open');
        sessionStorage.setItem("shownPopup", "1");
    }, [])

    const closeModal = () => {
        document.getElementById('body').classList.remove('modal-open');
        setPopup("");
    }

    return (
        <div className="popup-wrapper" onClick={closeModal}>
            <div className='popup'>
                <p>{popup}</p>
                <button>Continue</button>
            </div>
        </div>
    );
};

export default Popup