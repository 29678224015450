import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './OrdersList.css';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const OrdersList = ({ data, invoices = false, selectMode = false, select, selectedOrder = undefined }) => {
    let navigate = useNavigate();

    return (
        <Container maxWidth="lg" className="mt-20">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{invoices ? 'Invoice' : 'Order'}  #</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Shipping</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className={row._id === selectedOrder ? 'orders-row orders-row-selected' : 'orders-row'}
                                onClick={() => {
                                    if (selectMode) {
                                        select(row._id)
                                    } else {
                                        navigate(`/${invoices ? 'invoice' : 'order'}/${row._id}`)
                                    }
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    <p>{invoices ? row.invoice.invoiceNumber : (row.orderNumber || row.id)}</p>
                                </TableCell>
                                <TableCell><p>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(row.date || row.createdAt))}</p></TableCell>
                                <TableCell>
                                    <div className="orders-shipping">
                                        <p>{row.deliveryname || row.deliveryName}</p>
                                        <p>{row.deliverystreet || row.deliveryStreet}</p>
                                        <p>{row.deliverycity || row.deliveryCity}</p>
                                    </div>
                                </TableCell>
                                <TableCell><p>{invoices ? row.invoice.status : row.status}</p></TableCell>
                                <TableCell><p>€{(row.total + row.surcharge + row.shipping).toFixed(2)}</p></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default OrdersList