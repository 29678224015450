import './Carousel.css';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Slider = () => {
    const [products, setProducts] = useState([{}]);
    const navigate = useNavigate();

    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = () => {
        fetch(`${process.env.REACT_APP_API}/api/getProducts`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setProducts(data.filter(x => !x.archived));
                });
            }
        })
    }

    const Item = ({ item }) => {
        return (
            <Paper style={{ height: '400px', backgroundImage: `url(${item.img})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', color: item.dark ? 'black' : 'white', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                {item.name && <div style={{ marginTop: '50px', marginLeft: '50px' }}>
                    <h2>{item.name}</h2>
                    <p>{item.description}</p>
                    <p>€{(item.price).toFixed(2)}</p>
                </div>}

                <Button 
                    variant="contained" 
                    style={{ background: '#8CBD22', color: 'white', marginBottom: '50px', marginLeft: '50px' }}
                    onClick={() => navigate(`/product/${item._id}`)}
                >
                    Check it out!
                </Button>
            </Paper>
        )
    }

    return (
        <div className="carouselWrapper">
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth > 600 ? 'row' : 'column', alignItems: 'center' }}>
                <div className="mt-50" style={{ width: window.innerWidth > 600 ? '35%' : '100%', marginRight: '20px', display: 'flex', justifyContent: 'center' }}>
                    <img src="../../../logo_new.png" style={{ width: window.innerWidth > 600 ? '100%' : '50%' }} />
                </div>
                <div className="carousel mt-50">
                    <Carousel navButtonsAlwaysVisible={false} indicators={false} fullHeightHover={true} interval={3000} >
                        {
                            products.map( (item, i) => <Item key={i} item={item} /> )
                        }
                    </Carousel>
                </div>
            </div>
            
            <div className="carousel mt-20" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="carousel-nav carousel-nav-ikea" style={{ backgroundColor: '#0257a2', cursor: 'pointer' }}>
                    <img src="../../../shop_ikea1.jpg"></img>
                    <div className='carousel-left' style={{ cursor: 'pointer' }} onClick={() => document.getElementById('productsTitle').scrollIntoView({behavior: 'smooth'})}></div>
                    <div className='carousel-right' style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.ikea.com/it/it/', '_blank')}></div>
                </div>
                <div className="carousel-nav carousel-nav-bricoman" style={{ backgroundColor: '#160549', cursor: 'pointer' }}>
                    <img src="../../../shop_bricoman.jpg"></img>
                    <div className='carousel-left' style={{ cursor: 'pointer' }} onClick={() => navigate('bricoman')}></div>
                    <div className='carousel-right' style={{ cursor: 'pointer' }} onClick={() => window.open('https://www.tecnomat.it/it/', '_blank')}></div>
                </div>
            </div>
        </div>
    );
};

export default Slider