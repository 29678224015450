import './Payment.css';
import Navbar from '../../components/navbar/Navbar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import React from 'react';
import { toast } from 'react-toastify';

const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Payment = () => {
    const [status, setStatus] = useState("");
    const params = useParams();
    const query = useQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.status) {
            setStatus(params.status);
            verify();
        } else {
            navigate('/');
        }
    }, [params])

    const verify = () => {
        fetch(`${process.env.REACT_APP_API}/api/verifyPayment`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                transactionId: query.get("t"),
                orderCode: query.get("s"),
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Verified!')
                navigate('/');
            } else if (response.status === 500) {
                toast.error('Server error.')
                navigate('/');
            }
        });
    }

    return (
        <div className="payment">
            <Navbar />
            <div className="payment-content">
                <h1>Payment <span>{status}</span>!</h1>
                <h4>Please wait while we verify your request...</h4>
            </div>
        </div>
    );
}

export default Payment;