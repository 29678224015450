import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './ProductsList.css';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ProductsList = ({ data }) => {
    let navigate = useNavigate();

    return (
        <Container maxWidth="lg" className="mt-20">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Product #</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Archived</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                className='orders-row'
                                onClick={() => navigate(`/admin/editProduct/${row._id}`)}
                            >
                                <TableCell component="th" scope="row">
                                    <p>{row._id}</p>
                                </TableCell>
                                <TableCell><p>{row.name}</p></TableCell>
                                <TableCell><p>{row.description}</p></TableCell>
                                <TableCell><p>{row.price}</p></TableCell>
                                <TableCell><p>{row.archived ? 'Hidden' : 'Visible'}</p></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default ProductsList