import { useDispatch, useSelector } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import './Order.css';
import { useEffect, useState } from 'react';
import CartList from '../../components/CartList/CartList';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../../redux/cartSlice';
import PaymentOptions from '../../components/PaymentOptions/PaymentOptions';
import { Tooltip } from '@mui/material';

const tip = (<div><strong>DELIVERY WILL BE DONE THE FOLLOWING WEEK WHEN WE RECEIVE THE PAYMENT UNTIL TUESDAY WEATHER AND CONDITIONS PERMITTING:</strong><br></br>
DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A TUESDAY AS PER THE INDICATED ROUTE:<br></br>
VALLETTA, SANTA VENERA,HAMRUN, PIETA,FLORIANA, MSIDA, TA'XBIEX, GZIRA, SLIEMA, ST.JULIANS, SAN GWANN, SWIEQI, MADLIENA, PEMBROKE, BAHAR IC-CAGHAQ<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A WEDNESDAY AS PER THE INDICATED ROUTE:<br></br>
 BIRKIRKARA, SWATAR, IKLIN, BALZAN, LIJA, ATTARD, MOSTA,NAXXAR, GHARGHUR, BURMARRAD, SAN PAWL IL-BAHAR, MELLIEHA, MANIKATA, MGARR,MTARFA, RABAT, DINGLI<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A THURSDAY AS PER THE INDICATED ROUTE:<br></br>
ZEBBUG, SIGGIEWI, QORMI, MARSA,BORMLA, BIRGU, ISLA, KALKARA, FGURA, PAOLA ,TARXIEN,SANTA LUCIJA, XGHAJRA,ZABBAR, MARSASCALA, ZEJTUN ,MARSAXLOKK, BIRZEBBUGIA, GHAXAQ ,GUDJA, KIRKOP ,MQABBA,SAFI ,ZURRIEQ, QRENDI,LUQA<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN GOZO WILL BE DONE ON A FRIDAY AS PER THE INDICATED ROUTE:<br></br>
GHAJNSIELEM, QALA, XEWKIJA,NADUR, XAGHRA,SANNAT,KERCEM, MUNXAR,VICTORIA, FONTANA, XLENDI, GHARB,GHASRI, SAN LAWRENZ ,ZEBBUG ,MARSALFORN</div>);

const Order = () => {
    const cart = useSelector((state) => state.cartReducer.items)
    const [account, setAccount] = useState({});
    const [surcharge, setSurcharge] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [VAT, setVAT] = useState(0);
    const [total, setTotal] = useState(0);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getAccount();
    }, []);

    useEffect(() => {
        calculateTotal(cart)
    }, [cart]);

    useEffect(() => {
        calculateVAT(cart)
    }, [surcharge]);

    useEffect(() => {
        calculateVAT(cart)
    }, [shipping]);

    const calculateTotal = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        setTotal(sum);
        calculateSurcharge(cart);
        calculateShipping(cart);
    }

    const calculateSurcharge = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        if (sum === 0) setSurcharge(0)
        else if (sum < 100) setSurcharge(10)
        else setSurcharge(0)
    }

    const calculateShipping = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        if (sum > 500) setShipping(sum * 0.23)
        else setShipping(sum * 0.25)
    }

    const calculateVAT = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        setVAT((sum + surcharge + shipping) / 1.18 * 0.18);
    }

    const getAccount = () => {
        if (localStorage.getItem('account') == null) navigate('/login')
        fetch(`${process.env.REACT_APP_API}/api/getAccount/${localStorage.getItem('account')}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setAccount(data);
                });
            }
        })
    }

    const order = (payLater = false) => {     
        const name = document.getElementById("name").value;
        const campanyName = document.getElementById("campanyName").value;
        const VAT = document.getElementById("VAT").value;
        const street = document.getElementById("street").value;
        const city = document.getElementById("city").value;
        const postCode = document.getElementById("postCode").value;
        const phone = document.getElementById("phone").value;
        const email = document.getElementById("email").value;
        const deliveryname = document.getElementById("delivery-name").value;
        const deliverycampanyName = document.getElementById("delivery-campanyName").value;
        const deliveryVAT = document.getElementById("delivery-VAT").value;
        const deliverystreet = document.getElementById("delivery-street").value;
        const deliverycity = document.getElementById("delivery-city").value;
        const deliverypostCode = document.getElementById("delivery-postCode").value;
        const deliveryphone = document.getElementById("delivery-phone").value;
        const deliveryemail = document.getElementById("delivery-email").value;
        const note = document.getElementById("note").value;

        if (name === "") {
            toast.error('Name is required.');
            return;
        }
        if (street === "") {
            toast.error('Street is required.');
            return;
        }
        if (city === "") {
            toast.error('City is required.');
            return;
        }
        if (postCode === "") {
            toast.error('Post Code is required.');
            return;
        }
        if (phone === "") {
            toast.error('Phone is required.');
            return;
        }
        if (email === "") {
            toast.error('Email is required.');
            return;
        }
        if (deliveryname === "") {
            toast.error('Name is required.');
            return;
        }
        if (deliverystreet === "") {
            toast.error('Street is required.');
            return;
        }
        if (deliverycity === "") {
            toast.error('City is required.');
            return;
        }
        if (deliverypostCode === "") {
            toast.error('Post Code is required.');
            return;
        }
        if (deliveryphone === "") {
            toast.error('Phone is required.');
            return;
        }
        if (deliveryemail === "") {
            toast.error('Email is required.');
            return;
        }

        setLoading(true);

        fetch(`${process.env.REACT_APP_API}/api/order`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                account: localStorage.getItem('account'),
                name: name,
                campanyName: campanyName,
                VAT: VAT,
                street: street,
                city: city,
                postCode: postCode,
                phone: phone,
                email: email,
                deliveryname: deliveryname,
                deliverycampanyName: deliverycampanyName,
                deliveryVAT: deliveryVAT,
                deliverystreet: deliverystreet,
                deliverycity: deliverycity,
                deliverypostCode: deliverypostCode,
                deliveryphone: deliveryphone,
                deliveryemail: deliveryemail,
                note: note,
                cart: cart,
                payLater : payLater,
                method : selectedOption,
            })
        })
        .then(response => {
            setLoading(false);
            if (response.status === 200) {
                response.json().then(data => {
                    dispatch(clearCart());
                    toast.success('Order Submitted!')
                    if (payLater) {
                        navigate('/');
                    } else {
                        window.location = `https://www.vivapayments.com/web/checkout?ref=${data.orderCode}&color=8CBD22`;
                    }
                });
            } else if (response.status === 500) {
                response.json().then(data => {
                    toast.error(data)
                });
            }
        });
    }

    const setOption = (option) => {
        setSelectedOption(option);
    }

    return (
        <div className="order">
            <Navbar />

            <div className="order-info-wrapper mt-50">
                <div className="order-info">
                    {account && <form>
                        <h4 className="mt-0 mb-20">Billing Details</h4>

                        <div className="input-group">
                            <label>Full Name</label>
                            <input type="text" name="name" defaultValue={account.name} id="name"></input>
                        </div>

                        <div className="input-group">
                            <label>Company Name</label>
                            <input type="text" name="campanyName" defaultValue={account.campany} id="campanyName"></input>
                        </div>

                        <div className="input-group">
                            <label>VAT NO</label>
                            <input type="text" name="VAT" defaultValue={account.VAT} id="VAT"></input>
                        </div>

                        <div className="input-group">
                            <label>Street Address</label>
                            <input type="text" name="street" defaultValue={account.street} id="street"></input>
                        </div>

                        <div className="input-group">
                            <label>Town / City</label>
                            <input type="text" name="city" defaultValue={account.city} id="city"></input>
                        </div>

                        <div className="input-group">
                            <label>Post Code</label>
                            <input type="text" name="postCode" defaultValue={account.postCode} id="postCode"></input>
                        </div>

                        <div className="input-group">
                            <label>Phone</label>
                            <input type="tel" name="phone" defaultValue={account.phone} id="phone"></input>
                        </div>

                        <div className="input-group">
                            <label>Email</label>
                            <input type="email" name="email" defaultValue={account.email} id="email"></input>
                        </div>
                    </form>}
                </div>
                <div className="order-info">
                    <form>
                        <h4 className="mt-0 mb-20">Delivery Details</h4>

                        <div className="input-group">
                            <label>Full Name</label>
                            <input type="text" name="delivery-name" defaultValue={account.name} id="delivery-name"></input>
                        </div>

                        <div className="input-group">
                            <label>Company Name</label>
                            <input type="text" name="delivery-campanyName" defaultValue={account.campany} id="delivery-campanyName"></input>
                        </div>

                        <div className="input-group">
                            <label>VAT NO</label>
                            <input type="text" name="delivery-VAT" defaultValue={account.VAT} id="delivery-VAT"></input>
                        </div>

                        <div className="input-group">
                            <label>Street Address</label>
                            <input type="text" name="delivery-street" defaultValue={account.street} id="delivery-street"></input>
                        </div>

                        <div className="input-group">
                            <label>Town / City</label>
                            <input type="text" name="delivery-city" defaultValue={account.city} id="delivery-city"></input>
                        </div>

                        <div className="input-group">
                            <label>Post Code</label>
                            <input type="text" name="delivery-postCode" defaultValue={account.postCode} id="delivery-postCode"></input>
                        </div>

                        <div className="input-group">
                            <label>Phone</label>
                            <input type="tel" name="delivery-phone" defaultValue={account.phone} id="delivery-phone"></input>
                        </div>

                        <div className="input-group">
                            <label>Email</label>
                            <input type="email" name="delivery-email" defaultValue={account.email} id="delivery-email"></input>
                        </div>
                    </form>
                </div>
            </div>

            <div className="note mt-50">
                <form>
                    <div className="input-group">
                        <label>Order Note</label>
                        <textarea name="note" id="note"></textarea>
                    </div>
                </form>
            </div>

            <div className="order-wrapper">
                <CartList data={cart} showcase />

                <div className="order-price mt-50 mb-50">
                    <small>* All prices are inclusive of VAT</small>
                    <div className="order-pricing">
                        <p>ORDER TOTAL</p>
                        <h4>€{total.toFixed(2)}</h4>
                    </div>
                    <div className="order-pricing">
                        <p>SHIPPING</p>
                        <h4>€{shipping.toFixed(2)}</h4>
                    </div>
                    <div className="order-pricing">
                        <p>SURCHARGE</p>
                        <h4>€{surcharge.toFixed(2)}</h4>
                    </div>
                    {/* <div className="order-pricing">
                        <p>SUB TOTAL</p>
                        <h4>€749.00</h4>
                    </div> */}
                    {/* <div className="order-pricing">
                        <p>18% VAT</p>
                        <h4>€{VAT.toFixed(2)}</h4>
                    </div> */}
                    <hr></hr>
                    <div className="order-pricing">
                        <p>GRAND TOTAL</p>
                        <h4>€{(total + surcharge + shipping).toFixed(2)}</h4>
                    </div>
                    <PaymentOptions setOption={setOption} />

                    <div className="order-price-button">
                        {selectedOption === 'vivaWallet' && <button disabled={loading} onClick={() => order(false)}>Confirm & Pay</button>}
                        <button disabled={loading} onClick={() => order(true)}>Confirm & Pay Later</button>
                    </div>
                    <small>* The item/s you are ordering will be delivered to you asap depending on the availability of stock from shop. Kindly contact us for more information regarding this by email.</small><br></br><br></br>
                    <small>Please check the delivery <Tooltip disableFocusListener title={tip} placement="top"><span style={{ textDecoration: 'underline' }}>schedule</span></Tooltip> for more information.</small>
                </div>
            </div>
        </div>
    );
}

export default Order;