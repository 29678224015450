import Navbar from '../../components/navbar/Navbar';
import './Reports.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import ReportsList from '../../components/ReportsList/ReportsList';
import PrintHero from '../../components/PrintHero/PrintHero';

const formatDate = (date) => date.toISOString().split('T')[0];

const Reports = () => {
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState("invoice");
    const [startDate, setStartDate] = useState(formatDate(new Date())); // Defaults to today
    const [endDate, setEndDate] = useState(formatDate(new Date())); // Defaults to today
    const [printing, setPrinting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        setList([]);
    
        let url = `${process.env.REACT_APP_API}/api/getReport`;
    
        const params = new URLSearchParams();
    
        if (filter.length > 0) params.append('filter', filter);
        params.append('startDate', startDate);
        params.append('endDate', endDate);
    
        url += `?${params.toString()}`;
    
        fetch(url)
          .then((response) => {
            if (response.status === 200) {
              if (localStorage.getItem('account') !== null) {
                response.json().then((data) => setList(data));
              } else {
                navigate('/');
              }
            }
          })
          .catch((error) => {
            console.error('Error fetching the report:', error);
          });
      };

    const changeFilter = (e) => {
        setFilter(e.target.value);
    }

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };
    
      const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };    

    const print = () => {
        setPrinting(true);
        setTimeout(() => {
            window.print();
          }, "1000");
    }

    window.onbeforeprint = (event) => {
        setPrinting(true);
    };
    
    window.onafterprint = (event) => {
        setPrinting(false);
    };

    return (
        <div className="orders mb-50">
            {printing ? <PrintHero /> : <Navbar />}
            
            <h1 className="mt-50 mb-30 page-title">Reports</h1>
            {(localStorage.getItem('admin') == "true") && !printing && <Container maxWidth="lg" className="mt-20 orders-filters">
                <div style={{ display: 'flex' }}>
                    <div className="input-group">
                        <label>Search</label>
                        <select value={filter} onChange={changeFilter}>
                            <option value="invoice">Invoices</option>
                            <option value="credit">Credit Notes</option>
                        </select>
                    </div>
                    <div className="input-group">
                        <label>Start Date</label>
                        <input type="date" value={startDate} onChange={handleStartDateChange} />
                    </div>

                    <div className="input-group">
                        <label>End Date</label>
                        <input type="date" value={endDate} onChange={handleEndDateChange} />
                    </div>
                </div>
                <div className="search-button-wrapper">
                    <button onClick={() => getList()}>Search</button>
                    <button onClick={() => print()}>Print</button>
                </div>
            </Container>}

            {list.length > 0 && <ReportsList data={list} />}
        </div>
    );
}

export default Reports;