import { Link } from 'react-router-dom';
import './NoMatch.css';

const NoMatch = () => {
    return (
        <div className="nomatch">
            <div className="nomatch-content">
                <h1><span>404</span> Not Found</h1>
                <Link to="/">Home</Link>
            </div>
        </div>
    );
}

export default NoMatch;