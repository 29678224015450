import { useSelector } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import './InvoiceDetail.css';
import { useEffect, useState } from 'react';
import CartList from '../../components/CartList/CartList';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrintHero from '../../components/PrintHero/PrintHero';

const InvoiceDetail = () => {
    const old = false;
    const [order, setOrder] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [editing, setEditing] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [printingDetails, setPrintingDetails] = useState(false);
    const [printingItems, setPrintingItems] = useState(false);
    const [surcharge, setSurcharge] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [newSKU, setNewSKU] = useState("");
    const [newAmount, setNewAmount] = useState(0);
    const [newMissingSKU, setNewMissingSKU] = useState("");
    const [newMissingAmount, setNewMissingAmount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [extraInfo, setExtraInfo] = useState(false);
    const [extraInfoArray, setExtraInfoArray] = useState([]);
    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setAdmin(localStorage.getItem('admin'));
        if (params.id) {
            getOrder(params.id);
        } else {
            navigate('/');
        }
    }, [params])

    useEffect(() => {
        if (order && order.transactionId && order.orderCode) {
            verify()
        }
    }, [order])

    useEffect(() => {
        if (order && !extraInfo) {
            getExtraInfo()
            setExtraInfo(true)
        }
    }, [order])

    const getExtraInfo = () => {
        let SKUs = []
        let tempExtraInfoArray = []

        if (order.deliveryNote.length > 0) {
            order.deliveryNote.forEach(element => {
                SKUs.push(element.SKU)
            });
        }

        if (order.missingItems.length > 0) {
            order.missingItems.forEach(element => {
                SKUs.push(element.SKU)
            });
        }

        if (order.creditNote && order.creditNote.items.length > 0) {
            order.creditNote.items.forEach(element => {
                SKUs.push(element.SKU)
            });
        }

        SKUs.map(async (sku, index) => {
            getProductsByName(sku).then(response => {
                if (response.length > 0) {
                    tempExtraInfoArray.push({
                        owner: sku,
                        extraInfo: response[0]
                    })
                }

                if (index >= SKUs.length - 1) {
                    console.log(tempExtraInfoArray)
                    setExtraInfoArray(tempExtraInfoArray)
                }
            })
        })
    }

    const getOrder = (id) => {
        fetch(`${process.env.REACT_APP_API}/api/getOrder/${id}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    if (!data.invoice?.invoiceNumber) {
                        navigate(`/order/${id}`)
                    }
                    setOrder(data);
                    setSurcharge(data.surcharge);
                    setShipping(data.shipping);
                });
            }
        })
    }

    const verify = () => {
        fetch(`${process.env.REACT_APP_API}/api/verifyPayment`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                transactionId: order.transactionId,
                orderCode: order.orderCode,
            })
        })
        .then(response => {
            
        });
    }

    const changeStatus = (e) => {
        e.preventDefault();

        fetch(`${process.env.REACT_APP_API}/api/changeStatus/${order._id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                status: e.target.value,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed status!');
                getOrder(params.id);
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }

    const changeSurcharge = (e) => {
        setSurcharge(e.target.value)
    }

    const changeShipping = (e) => {
        setShipping(e.target.value)
    }

    const changeNewSKU = (e) => {
        setNewSKU(e.target.value)
    }
    
    const changeNewAmount = (e) => {
        setNewAmount(e.target.value)
    }

    const changeNewMissingSKU = (e) => {
        setNewMissingSKU(e.target.value)
    }
    
    const changeNewMissingAmount = (e) => {
        setNewMissingAmount(e.target.value)
    }

    const updatePayment = (e) => {
        e.preventDefault();

        fetch(`${process.env.REACT_APP_API}/api/changePayment/${order._id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                surcharge: document.getElementById('surcharge').value,
                shipping: document.getElementById('shipping').value
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed payment details!');
                getOrder(params.id);
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }

    const updateCart = (cart) => {
        setOrder({...order, cart: cart});
    }

    const enableEditing = () => {
        setEditing(true);
    }

    const saveEditing = () => {
        setEditing(false);

        fetch(`${process.env.REACT_APP_API}/api/changeCart/${order._id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                cart: order.cart,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed order!');
                getOrder(params.id);
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }

    const cancelEditing = () => {
        setEditing(false);
        getOrder(params.id);
    }

    const changeSKU = (e, index) => {
        let tempOrder = order;
        let tempDeliveryNote = order.deliveryNote;

        setOrder({
            ...tempOrder,
            deliveryNote: tempDeliveryNote.map((x, i) => {
                if (i === index) return {
                    ...x,
                    SKU: e.target.value
                }
                else return x
            })
        })
    }

    const changeAmount = (e, index) => {
        let tempOrder = order;
        let tempDeliveryNote = order.deliveryNote;

        setOrder({
            ...tempOrder,
            deliveryNote: tempDeliveryNote.map((x, i) => {
                if (i === index) return {
                    ...x,
                    amount: e.target.value
                }
                else return x
            })
        })
    }

    const changeMissingSKU = (e, index) => {
        let tempOrder = order;
        let tempMissingItems = order.missingItems;

        setOrder({
            ...tempOrder,
            missingItems: tempMissingItems.map((x, i) => {
                if (i === index) return {
                    ...x,
                    SKU: e.target.value
                }
                else return x
            })
        })
    }

    const changeMissingAmount = (e, index) => {
        let tempOrder = order;
        let tempMissingItems = order.missingItems;

        setOrder({
            ...tempOrder,
            missingItems: tempMissingItems.map((x, i) => {
                if (i === index) return {
                    ...x,
                    amount: e.target.value
                }
                else return x
            })
        })
    }

    const addNewDeliveryNote = (e, save = false) => {
        e.preventDefault();

        let items = save ? [...order.deliveryNote] : [...order.deliveryNote, {
            SKU: newSKU,
            amount: newAmount
        }]

        fetch(`${process.env.REACT_APP_API}/api/changeDeliveryNote/${order._id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                deliveryNote: items,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed delivery note!');
                getOrder(params.id);
                setNewSKU("");
                setNewAmount(0);
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }

    const addNewMissingItems = (e, save = false) => {
        e.preventDefault();

        let items = save ? [...order.missingItems] : [...order.missingItems, {
            SKU: newMissingSKU,
            amount: newMissingAmount
        }]

        fetch(`${process.env.REACT_APP_API}/api/changeMissingItems/${order._id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                missingItems: items,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed delivery note!');
                getOrder(params.id);
                setNewSKU("");
                setNewAmount(0);
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }

    const print = () => {
        setPrinting(true);
        setTimeout(() => {
            window.print();
          }, "1000");
    }

    window.onbeforeprint = (event) => {
        setPrinting(true);
    };
    
    window.onafterprint = (event) => {
        setPrinting(false);
        setPrintingDetails(false);
        setPrintingItems(false);
    };

    const pay = () => {
        fetch(`${process.env.REACT_APP_API}/api/createPaymentOrderEndpoint`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                orderNumber: order.orderNumber,
                name: order.name,
                phone: order.phone,
                email: order.email,
                total: (order.total + order.surcharge + order.shipping).toFixed(2),
                payLater: false,
                id: order._id,
            })
        })
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    window.location = `https://demo.vivapayments.com/web/checkout?ref=${data.orderCode}&color=0A6CFF`;
                });
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    const deleteDeliveryNote = (index) => {
        let tempOrder = order;
        let tempDeliveryNote = order.deliveryNote;

        setOrder({
            ...tempOrder,
            deliveryNote: tempDeliveryNote.filter((x, i) => i !== index)
        })
    }

    const deleteMissingItem = (index) => {
        let tempOrder = order;
        let tempMissingItems = order.missingItems;

        setOrder({
            ...tempOrder,
            missingItems: tempMissingItems.filter((x, i) => i !== index)
        })
    }

    const printDetails = (e) => {
        e.preventDefault();

        setPrinting(true);
        setPrintingDetails(true);
        print();
    }

    const printItems = (e) => {
        e.preventDefault();

        setPrinting(true);
        setPrintingItems(true);
        print();
    }

    const getExtraInfoBySKU = (sku) => {
        return extraInfoArray.find(x => x.owner === sku)?.extraInfo
    }

    const getProductsByName = (name) => {
        return fetch(`${process.env.REACT_APP_API}/api/getProductsByName/${name.trim()}/0/10`, {})
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => console.warn(error));
    }

    return (
        <div className={printing ? 'order-detail order-detail-printing mb-50' : 'order-detail mb-50'}>
            {printing ? <PrintHero /> : <Navbar />}
            
            {admin == "true" && <div className={printing ? 'order-detail-toolbar' : 'order-detail-toolbar'}>
                {order && <h1 className={printing && 'mt-5 mb-0'}>{printingDetails ? 'Delivery Note' : printingItems ? 'Missing Note' : 'Invoice'} #{order.invoice.invoiceNumber}</h1>}
                {order  && <div style={{ display: 'flex', alignItems: 'center' }}>
                    {order.status === "APPROVED" ? <p>{order.status}</p> :
                    <select value={order.status} onChange={changeStatus}>
                        <option value="SUBMITTED">SUBMITTED</option>
                        <option value="APPROVED">APPROVED</option>
                        <option value="DECLINED">DECLINED</option>
                        <option value="SHIPPED">SHIPPED</option>
                    </select>
                    }
                    {!printing && admin == "true" && <button onClick={() => print()} style={{ margin: '0px 0px 0px 10px' }}>Print</button>}
                    {!printing && admin == "true" && <button onClick={() => navigate(`/order/${params.id}`)} style={{ margin: '0px 0px 0px 10px' }}>Order</button>}
                    {!printing && admin == "true" && <button onClick={() => navigate(`/createcreditnote/${params.id}`)} style={{ margin: '0px 0px 0px 10px', width: 'fit-content', padding: '0px 15px' }}>Credit Note</button>}
                    {!printing && admin == "false" && order.invoice.status === "UNPAID" && <button onClick={() => pay()} style={{ margin: '0px 0px 0px 10px' }}>Pay</button>}
                </div>}
            </div>}

            <div className={printing ? 'order-detail-toolbar-info mt-0 noshadow' : 'order-detail-toolbar-info mt-50'}>
                {order && 
                    <div className="input-group">
                        <label>Invoice Number</label>
                        <p>{order.invoice.invoiceNumber}</p>
                    </div>
                }
                {order && 
                    <div className="input-group">
                        <label>Invoice Date</label>
                        <p>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(order.invoice.date))}</p>
                    </div>
                }
                {order && 
                    <div className="input-group">
                        <label>Invoice Total</label>
                        <p>€{(order.total + order.surcharge + order.shipping).toFixed(2)}</p>
                    </div>
                }
                {order && 
                    <div className="input-group">
                        <label>Invoice Status</label>
                        <p>{order.invoice.status}</p>
                    </div>
                }
            </div>

            <div className={`${printing ? 'order-detail-wrapper-printing': 'order-detail-wrapper'}`}>
                <div className={printing ? 'cartlist-printing' : ''}>
                    {order && !printingDetails && !printingItems && <CartList data={order.cart} showcase={true} editing={editing} update={updateCart} printing={printing} />}
                    {/* {order && !editing && order.status !== "APPROVED" && <button onClick={() => enableEditing()}>Edit</button>}
                    {order && editing && <div>
                        <button onClick={() => saveEditing()} style={{ marginRight: '20px' }}>Save</button>
                        <button className="delete" onClick={() => cancelEditing()}>Cancel</button>
                    </div>} */}

                    {order && !printingDetails && !printingItems &&<div className="input-group mt-50">
                        <label>Order Note</label>
                        <p>{order.note}</p>
                    </div>}

                    {order && (!printing || printingDetails) && admin == "true" && <div className="mt-50">
                        <label className="mb-20">Delivery Notes</label><br></br>
                        {order.deliveryNote && order.deliveryNote.map((item, index) => {
                            const extraInfo = getExtraInfoBySKU(item.SKU)
                            return <div style={{ margin: printingDetails ? '30px 0px' : '' }}>
                                {extraInfo && <div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={extraInfo.image} style={{ width:'50px', height:'50px', marginRight: '10px' }} />
                                        <h4 style={{ marginRight: '10px' }}>€{extraInfo.price.currentPrice.toFixed(2)}</h4>
                                        <p>{extraInfo.imageAlt}</p>
                                    </div>
                                </div>}
                            <div className={index === 0 ? extraInfo ? 'input-group' : 'input-group mt-20' : 'input-group'} style={{ display: 'flex', flexDirection: 'row', height: '30px' }}>
                                <p className='m-0'>SKU: <input type="text" value={item.SKU} onChange={(e) => changeSKU(e, index)} placeholder="SKU12414" /></p>
                                <p className='m-0'>Amount: <input type="number" value={item.amount} onChange={(e) => changeAmount(e, index)} placeholder="2" /></p>
                                {!printingDetails && <button className='delete mt-0' onClick={() => deleteDeliveryNote(index)}>Delete</button>}
                            </div>
                        </div>})}
                        {!printingDetails && <small className='mt-20'>Add new</small>}
                        {!printingDetails && <div className="input-group" style={{ display: 'flex', flexDirection: 'row', height: '30px' }}>
                            <p className='m-0'>SKU: <input type="text" value={newSKU} onChange={(e) => changeNewSKU(e)} placeholder="SKU12414" /></p>
                            <p className='m-0'>Amount: <input type="number" value={newAmount} onChange={(e) => changeNewAmount(e)} placeholder="2" /></p>
                        </div>}
                        {!printingDetails && <button onClick={(e) => addNewDeliveryNote(e)} className="mt-0">Add</button>}
                        {!printingDetails && <button onClick={(e) => addNewDeliveryNote(e, true)} className="mt-0" style={{ marginLeft: '10px' }}>Save</button>}
                        {!printingDetails && <button onClick={(e) => printDetails(e)} className="mt-0" style={{ marginLeft: '10px' }}>Print</button>}
                    </div>}

                    {order && (!printing || printingItems) && admin == "true" && <div className="mt-50">
                        <label className="mb-20">Missing note</label><br></br>
                        {order.deliveryNote && order.missingItems.map((item, index) => {
                            const extraInfo = getExtraInfoBySKU(item.SKU)
                            return <div style={{ margin: printingItems ? '30px 0px' : '' }}>
                                {extraInfo && <div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={extraInfo.image} style={{ width:'50px', height:'50px', marginRight: '10px' }} />
                                        <h4 style={{ marginRight: '10px' }}>€{extraInfo.price.currentPrice.toFixed(2)}</h4>
                                        <p>{extraInfo.imageAlt}</p>
                                    </div>
                                </div>}
                            <div className={index === 0 ? extraInfo ? 'input-group' : 'input-group mt-20' : 'input-group'} style={{ display: 'flex', flexDirection: 'row', height: '30px' }}>
                                <p className='m-0'>SKU: <input type="text" value={item.SKU} onChange={(e) => changeMissingSKU(e, index)} placeholder="SKU12414" /></p>
                                <p className='m-0'>Amount: <input type="number" value={item.amount} onChange={(e) => changeMissingAmount(e, index)} placeholder="2" /></p>
                                {!printingItems && <button className='delete mt-0' onClick={() => deleteMissingItem(index)}>Delete</button>}
                                </div>
                        </div>})}
                        {!printingItems && <small className='mt-20'>Add new</small>}
                        {!printingItems && <div className="input-group" style={{ display: 'flex', flexDirection: 'row', height: '30px' }}>
                            <p className='m-0'>SKU: <input type="text" value={newMissingSKU} onChange={(e) => changeNewMissingSKU(e)} placeholder="SKU12414" /></p>
                            <p className='m-0'>Amount: <input type="number" value={newMissingAmount} onChange={(e) => changeNewMissingAmount(e)} placeholder="2" /></p>
                        </div>}
                        {!printingItems && <button onClick={(e) => addNewMissingItems(e)} className="mt-0">Add</button>}
                        {!printingItems && <button onClick={(e) => addNewMissingItems(e, true)} className="mt-0" style={{ marginLeft: '10px' }}>Save</button>}
                        {!printingItems && <button onClick={(e) => printItems(e)} className="mt-0" style={{ marginLeft: '10px' }}>Print</button>}
                    </div>}
                </div>

                {<div className={`${printing ? 'order-detail-price-wrapper-printing ' : 'order-detail-price-wrapper'}`}>
                    {!printingDetails && !printingItems && <div className={`order-detail-price mt-20 ${printing && 'noshadow'}`}>
                        {order && <form>
                            <h4 className={`mt-0 ${printing ? 'mb-10 mt-10' : 'mb-20'}`}>Invoice Details</h4>

                            <div className={printing && 'order-detail-price-printing'}>
                                <div>
                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Order Total</label>
                                        <p>€{order.total.toFixed(2)}</p>
                                    </div>

                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Surcharge Total</label>
                                        {order.status === "APPROVED" ? <p>€{order.surcharge.toFixed(2)}</p> : <p>€ <input defaultValue={order.surcharge.toFixed(2)} id="surcharge" name="surcharge" style={{ width: '50px' }} /></p>}
                                    </div>

                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Shipping Total</label>
                                        {order.status === "APPROVED" ? <p>€{order.shipping.toFixed(2)}</p> : <p>€ <input defaultValue={order.shipping.toFixed(2)} id="shipping" name="shipping" style={{ width: '50px' }} /></p>}
                                    </div>
                                </div>

                                <div>
                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>VAT (18%)</label>
                                        <p>€{((order.total + order.surcharge + order.shipping) / 1.18 * 0.18).toFixed(2)}</p>
                                    </div>

                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Combined Total</label>
                                        <p>€{(order.total + order.shipping + order.surcharge).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>

                            {order.method && order.method != 'vivaWallet' && <div className="input-group">
                                <label>Method</label>
                                <p>{order.method}</p>
                            </div>}

                            {order.status !== "APPROVED" && !printing && <button onClick={updatePayment}>Change</button>}
                        </form>}
                    </div>}
                    {!printingDetails && !printingItems && <div className={`order-detail-price ${printing ? 'noshadow' : 'mt-20'}`}>
                        {order && <form>
                            <h4 className={`mt-0 ${printing ? 'mb-10' : 'mb-20'}`}>Billing Details</h4>

                            <div className={printing && 'order-detail-price-printing'}>
                                <div>
                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Full Name</label>
                                <input defaultValue={old ? order.owner?.name : order.name} id="name" name="name" />
                            </div>

                            {order.company && <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Company Name</label>
                                <input defaultValue={old ? order.owner?.company : order.company} id="company" name="company" />
                            </div>}

                            {order.VAT && <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>VAT NO</label>
                                <input defaultValue={old ? order.owner?.VAT : order.VAT} id="VAT" name="VAT" />
                            </div>}

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Street Address</label>
                                <input defaultValue={old ? order.owner?.street : order.street} id="street" name="street" />
                            </div>
                            </div>
                            <div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Town / City</label>
                                <input defaultValue={old ? order.owner?.city : order.city} id="city" name="city" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Post Code</label>
                                <input defaultValue={old ? order.owner?.postCode : order.postCode} id="postCode" name="postCode" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Phone</label>
                                <input defaultValue={old ? order.owner?.phone : order.phone} id="phone" name="phone" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Email</label>
                                <input defaultValue={old ? order.owner?.email : order.email} id="email" name="email" />
                            </div>
                            </div>
                            </div>
                        </form>}
                    </div>}
                    <div className={`order-detail-price ${printing ? 'noshadow' : 'mt-20'}`}>
                        {order && <form>
                            <h4 className={`mt-0 ${printing ? 'mb-10 mt-10' : 'mb-20'}`}>Delivery Details</h4>

                            <div className={printing && 'order-detail-price-printing'}>
                                <div>
                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Full Name</label>
                                <input defaultValue={order.deliveryname || order.deliveryName} id="deliveryname" name="deliveryname" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Company Name</label>
                                <input defaultValue={order.deliverycompany || order.deliveryCompany} id="deliverycompany" name="deliverycompany" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>VAT NO</label>
                                <input defaultValue={order.deliveryVAT || order.deliveryVAT} id="deliveryVAT" name="deliveryVAT" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Street Address</label>
                                <input defaultValue={order.deliverystreet || order.deliveryStreet} id="deliverystreet" name="deliverystreet" />
                            </div>
                            </div>
                            <div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Town / City</label>
                                <input defaultValue={order.deliverycity || order.deliveryCity} id="deliverycity" name="deliverycity" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Post Code</label>
                                <input defaultValue={order.deliverypostCode || order.deliveryPostCode} id="deliverypostCode" name="deliverypostCode" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Phone</label>
                                <input defaultValue={order.deliveryphone || order.deliveryPhone} id="deliveryphone" name="deliveryphone" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Email</label>
                                <input defaultValue={order.deliveryemail || order.deliveryEmail} id="deliveryemail" name="deliveryemail" />
                            </div>
                            </div>
                            </div>
                        </form>}
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default InvoiceDetail;