import { FaFacebook } from 'react-icons/fa';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Tooltip } from '@mui/material';
import Popup from '../popup/Popup';

const tip = (<div><strong>DELIVERY WILL BE DONE THE FOLLOWING WEEK WHEN WE RECEIVE THE PAYMENT UNTIL TUESDAY WEATHER AND CONDITIONS PERMITTING:</strong><br></br>
DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A TUESDAY AS PER THE INDICATED ROUTE:<br></br>
VALLETTA, SANTA VENERA,HAMRUN, PIETA,FLORIANA, MSIDA, TA'XBIEX, GZIRA, SLIEMA, ST.JULIANS, SAN GWANN, SWIEQI, MADLIENA, PEMBROKE, BAHAR IC-CAGHAQ<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A WEDNESDAY AS PER THE INDICATED ROUTE:<br></br>
 BIRKIRKARA, SWATAR, IKLIN, BALZAN, LIJA, ATTARD, MOSTA,NAXXAR, GHARGHUR, BURMARRAD, SAN PAWL IL-BAHAR, MELLIEHA, MANIKATA, MGARR,MTARFA, RABAT, DINGLI<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN MALTA WILL BE DONE ON A THURSDAY AS PER THE INDICATED ROUTE:<br></br>
ZEBBUG, SIGGIEWI, QORMI, MARSA,BORMLA, BIRGU, ISLA, KALKARA, FGURA, PAOLA, TARXIEN,SANTA LUCIJA, XGHAJRA,ZABBAR, MARSASCALA, ZEJTUN, MARSAXLOKK, BIRZEBBUGIA, GHAXAQ, GUDJA, KIRKOP, MQABBA,SAFI, ZURRIEQ, QRENDI,LUQA<br></br><br></br>

DELIVERIES AT ADDRESSES IN THE FOLLOWING LOCATIONS IN GOZO WILL BE DONE ON A FRIDAY AS PER THE INDICATED ROUTE:<br></br>
GHAJNSIELEM, QALA, XEWKIJA,NADUR, XAGHRA,SANNAT,KERCEM, MUNXAR,VICTORIA, FONTANA, XLENDI, GHARB,GHASRI, SAN LAWRENZ, ZEBBUG, MARSALFORN</div>);

const Footer = ({ hero = false }) => {
    const [popups, setPopups] = useState("");
    const [popup, setPopup] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        getPopup();
    }, []);


    const getPopup = () => {
        fetch(`${process.env.REACT_APP_API}/api/getPopups`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setPopups(data);
                });
            }
        })
    }

    return (
        <div className="footer">
            {/* {popup && popup.length > 0 && <Popup popup={tip} setPopup={setPopup} />} */}
            {hero && popups && popups.length >= 4 && <div className='footer-note'>
                <h4>{popups[2].message}</h4>
                <p style={{ whiteSpace: 'pre-line' }}>{popups[3].message}</p>
            </div>}
            {hero && <div className='footer-hero'>
                <div className='footer-hero-item'>
                    <h4>WEEKLY DELIVERIES SCHEDULE</h4>
                    <p>Deliveries will be done as per the delivery <Tooltip disableFocusListener title={tip} placement="top"><span style={{ textDecoration: 'underline' }}>schedule</span></Tooltip>.</p>
                </div>
                <div className='footer-hero-item'>
                    <h4>NO MINIMUM ORDER</h4>
                    <p>Orders under €100 will be charged an additional home delivery fee of €10.</p>
                </div>
                <div className='footer-hero-item'>
                    <h4>SECURE PAYMENTS</h4>
                    <p>Safe and secure online payments on your orders.</p>
                </div>
            </div>}
            <div className='footer-nav'>
                <div className='footer-nav-item'>
                    <img src="../../../logo_new.png" onClick={() => navigate('/')} />
                </div>
                <div className='footer-nav-item'>
                    <h4>Navigation</h4>
                    <p onClick={() => navigate('/')}>Home</p>
                    <p onClick={() => navigate('/privacy')}>Privacy Policy</p>
                    <p><Tooltip disableFocusListener title={tip} placement="top"><span style={{ textDecoration: 'underline' }}>Weekly Delivery Schedule</span></Tooltip></p>
                    {/* <p onClick={() => setPopup('test')}>Schedule</p> */}
                    <p onClick={() => navigate('/terms')}>Terms and Conditions</p>
                    <p onClick={() => navigate('/contact')}>Contact Forms</p>
                    <h4>Social</h4>
                    <FaFacebook size="32px" />
                </div>
                <div className='footer-nav-item'>
                    <h4>Contact</h4>
                    <p>SICILY SHOPPING TO MALTA LTD, TRIQ BURMARRAD MIDLANDS MICRO ENTERPRISE PARK WAREHOUSE E 13, IN-NAXXAR NXR 6345</p>
                    <p><strong><strong>OPENING HOURS:</strong></strong></p>
                    <p>Mon - Wednesday: 8.00am - 5.00pm,. Saturday from 8.00am - 1.00pm</p>
                    <p>Thursday and Friday and public holidays: closed.</p>
                    <p><strong>Warehouse:</strong></p>
                    <p>Pick ups: Mondays, Tuesday, Wednesdays from: 8.00am - 5.00pm, Saturdays from 8.00am - 1.00pm  always calling before picking up on +35699456742</p>
                    <p><strong>WhatsApp Message:</strong> +35699456742</p>
                    <p><strong>Email:</strong> sicilyshoppingtomalta@gmail.com</p>
                </div>
                {/* <div className='footer-nav-item'>
                    <h4>Social</h4>
                    <FaFacebook size="32px" />
                </div> */}
            </div>
            <div className='footer-copyright'>
                <p>© Copyright SicilyShopping</p>
            </div>
        </div>
    );
};

export default Footer