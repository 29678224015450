import Navbar from '../../components/navbar/Navbar';
import './Users.css';
import UsersList from '../../components/UsersList/UsersList';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

const Users = () => {
    const [accounts, setAccounts] = useState([]);
    const [query, setQuery] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        getUsers()
    }, []);

    const getUsers = () => {
        let url = `${process.env.REACT_APP_API}/api/getAccounts`;
        if (query.length > 0) url += ('?query=' + query)

        fetch(url, {})
        .then(response => {
            if (response.status === 200) {
                if (localStorage.getItem('account') !== null && localStorage.getItem('admin') == "true") {
                    response.json().then(data => setAccounts(data));
                } else {
                    navigate('/')
                }
            }
        })
    }

    const changeQuery = (e) => {
        setQuery(e.target.value);
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            getUsers();
        }
    };

    return (
        <div className="users mb-50">
            <Navbar />
            
            <h1 className="mt-50 page-title">Customers</h1>
            {(localStorage.getItem('admin') == "true") && <Container maxWidth="lg" className="mt-20 orders-filters">
                <div style={{ display: 'flex' }}>
                    <div className="input-group">
                        <label>Search</label>
                        <input type="text" value={query} onChange={changeQuery} onKeyDown={handleKeyDown}></input>
                    </div>
                </div>
                <div className="search-button-wrapper">
                    <button onClick={() => getUsers()}>Search</button>
                </div>
            </Container>}
            {accounts.length > 0 && <UsersList data={accounts} />}
        </div>
    );
}

export default Users;