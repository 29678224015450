import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaBoxOpen, FaCartArrowDown, FaFolderOpen, FaHome, FaUser, FaUserCog, FaUserFriends, FaUserPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const Navbar = () => {
    const cart = useSelector((state) => state.cartReducer.items)
    const [total, setTotal] = useState(0);

    useEffect(() => {
        calculateTotal(cart)
    }, [cart]);

    const calculateTotal = (cart) => {
        let sum = 0;

        cart.forEach(item => {
            sum += (item.price * item.amount)
        });

        setTotal(sum);
    }

    return (
        <div className="homeNav">
            <div className="navbar-wrapper">
                <Link to="/" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                    <div className="navbar-item">
                        <FaHome color="#FFF" size="20px"/>
                        <p>Home</p>
                    </div>
                </Link>
            </div>
            <div>
                {(localStorage.getItem('account') !== null) ?  <div className="navbar-wrapper">
                    {localStorage.getItem('admin') == "true" && <Link to="/users" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaUserFriends color="#FFF" size="20px"/>
                            <p>Users</p>
                        </div>
                    </Link>}
                    {localStorage.getItem('admin') == "true" && <Link to="/admin" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaFolderOpen color="#FFF" size="20px"/>
                            <p>Admin</p>
                        </div>
                    </Link>}
                    <Link to="/orders" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaBoxOpen color="#FFF" size="20px"/>
                            <p>Orders</p>
                        </div>
                    </Link>
                    <Link to="/invoices" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaBoxOpen color="#FFF" size="20px"/>
                            <p>Invoices</p>
                        </div>
                    </Link>
                    {localStorage.getItem('admin') == "true" && <Link to="/creditnotes" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaBoxOpen color="#FFF" size="20px"/>
                            <p>Credit Notes</p>
                        </div>
                    </Link>}
                    {localStorage.getItem('admin') == "true" && <Link to="/reports" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaBoxOpen color="#FFF" size="20px"/>
                            <p>Reports</p>
                        </div>
                    </Link>}
                    <Link to="/account" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaUserCog color="#FFF" size="20px"/>
                            <p>Account</p>
                        </div>
                    </Link>
                    <Link to="/cart" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaCartArrowDown color="#FFF" size="20px"/>
                            <p>€{total.toFixed(2)}</p>
                        </div>
                    </Link>
                </div> : <div className="navbar-wrapper">
                    <Link to="/login" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaUser color="#FFF" size="20px"/>
                            <p>LOGIN</p>
                        </div>
                    </Link>
                    <Link to="/register" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaUserPlus color="#FFF" size="20px"/>
                            <p>REGISTER</p>
                        </div>
                    </Link>
                    <Link to="/cart" className={localStorage.getItem('admin') == "true" ? 'admin-nav' : ''}>
                        <div className="navbar-item">
                            <FaCartArrowDown color="#FFF" size="20px"/>
                            <p>€{total.toFixed(2)}</p>
                        </div>
                    </Link>
                </div>
                }
            </div>
        </div>
    );
};

export default Navbar