// import { configureStore } from '@reduxjs/toolkit'
// import cartReducer from './redux/cartSlice'

// export const store = configureStore({
//   reducer: {
//     cart: cartReducer,
//   },
// })

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import cartReducer from './redux/cartSlice'
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk'

const reducers = combineReducers({
  cartReducer          
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export default store;