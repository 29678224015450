import { useNavigate } from 'react-router-dom';
import './ProductItem.css';
import { useDispatch } from 'react-redux'
import { add } from '../../redux/cartSlice'
import { toast } from 'react-toastify';
import { useState } from 'react';
import { FaCartPlus } from 'react-icons/fa';

const ProductItem = ({ data, small = false, updateProduct = undefined }) => {
    let navigate = useNavigate();
    const [amount, setAmount] = useState(1);
    const dispatch = useDispatch()

    const handleChange = (e) => {
        e.preventDefault;

        setAmount(e.target.value);
    }

    const handleAdd = (data) => {
        if (updateProduct !== undefined) {
            updateProduct(data, amount)
        } else {
            dispatch(add({
                id: data.id,
                name: data.name,
                price: data.price.currentPrice,
                img: data.image,
                amount: amount,
            }))
        }
        toast.success(`${data.name} added to cart!`, {
            toastId: Math.floor(Math.random() * 100000),
        })
    }

    return (
        <div className={small ? 'product product-small' : 'product'}>
            <div className="imgWrapper" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.id}`)}>
                {data?.contextualImageUrl && <img src={data.contextualImageUrl} alt={data.imageAlt} className="altImg"></img>}
                {data?.image && <img src={data.image} alt={data.imageAlt} className="mainImg"></img>}
            </div>
            <h4 className="product-productname" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.id}`)}>{data.name}</h4>
            <p className="product-productfullname" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.id}`)}>{data.imageAlt.slice(data.name.length + 1)}</p>
            <small className="product-productsku mt-10">{data.id.replace(/(\d{3})(?=\d)/g, '$1.')}</small>
            <p className="product-productprice mt-10" onClick={() => updateProduct ? handleAdd(data) : navigate(`/product/${data.id}`)}>€{data.price.currentPrice.toFixed(2)}</p>
            <div className="product-tools">
                <input type="number" min="1" value={amount} onChange={handleChange} ></input>
                <button
                    className='homeButton'
                    onClick={() => handleAdd(data)}
                ><FaCartPlus></FaCartPlus></button>
            </div>
        </div>
    );
};

export default ProductItem