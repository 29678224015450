import { useEffect } from 'react';
import './SitePopup.css';

const SitePopup = ({ popup, setPopup }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
        sessionStorage.setItem("shownSitePopup", "1");
    }, [])

    const closeModal = () => {
        setPopup(false);
    }

    const oldSite = () => {
        window.location.href = "https://sicilyshopping.wpx.rightbrain.cloud/";
    }

    return (
        <div className="sitepopup-wrapper" >
            <div className='sitepopup'>
                <h4>Do you want to visit the old site or the new site?</h4>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button onClick={oldSite}>Continue to Old Website</button>
                    <button onClick={closeModal}>Continue to New Website</button>
                </div>
            </div>
        </div>
    );
};

export default SitePopup