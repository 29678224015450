import { Link, useNavigate } from 'react-router-dom';
import './Register.css';
import Navbar from '../../components/navbar/Navbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    let navigate = useNavigate();

    const handleRegisterSubmit = (event) => {
        event.preventDefault();
    
        fetch(`${process.env.REACT_APP_API}/api/register`, {
            method: 'POST',
            
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                email: event.target.email.value,
                password: event.target.password.value,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Registered Succesfull!')
                navigate('/login');
            } else if (response.status === 409) {
                toast.error('Account already exists.')
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    return (
        <div className="register">
            <Navbar />

            <div className="register-inner">
                <form onSubmit={handleRegisterSubmit}>
                    <div>
                        <h4>Register</h4>
                    </div>

                    <div className="input-group">
                        <label>Email</label>
                        <input type="email" name="email"></input>
                    </div>

                    <div className="input-group">
                        <label>Password</label>
                        <input type="password" name="password"></input>
                    </div>

                    <button>Register</button>
                </form>
            </div>
        </div>
    );
}

export default Register;