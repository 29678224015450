import Navbar from '../../components/navbar/Navbar';
import './Admin.css';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ProductsList from '../../components/ProductsList/ProductsList';

const Admin = () => {
    const [popups, setPopups] = useState("");
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('account') == null || localStorage.getItem('admin') != "true") navigate('/login')
        getPopup();
        getProducts();
    }, []);


    const getPopup = () => {
        fetch(`${process.env.REACT_APP_API}/api/getPopups`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setPopups(data);
                });
            }
        })
    }

    const getProducts = () => {
        fetch(`${process.env.REACT_APP_API}/api/getProducts`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setProducts(data);
                });
            }
        })
    }

    const updatePopup = (e) => {
        e.preventDefault();
        const popup = document.getElementById("popup").value;

        fetch(`${process.env.REACT_APP_API}/api/updatePopup`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                popup: popup,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed popup!')
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    const updateBanner = (e) => {
        e.preventDefault();
        const title = document.getElementById("title").value;
        const banner = document.getElementById("banner").value;

        fetch(`${process.env.REACT_APP_API}/api/updateBanner`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                title: title,
                banner: banner,
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed Banner!')
            } else if (response.status === 500) {
                toast.error('Server error.')
            }
        });
    }

    const auth = () => {
        fetch(`${process.env.REACT_APP_API}/api/getAuthUrl`, {})
        .then(response => response.json())
        .then (data => window.location = data)
    }

    return (
        <div className="admin">
            <Navbar />

            <h1 className="mt-50 page-title">Admin</h1>

            <div className="admin-wrapper mt-20 mb-50">
                {popups && <div className="admin-tools-wrapper">
                    <div className="admin-tools">
                    <form>
                            <h4 className="mt-0 mb-20">Change Messages</h4>

                            <div className="input-group" style={{ height: "80px" }}>
                                <label>Popup</label>
                                <textarea defaultValue={popups[0].message} name="popup" id="popup"></textarea>
                            </div>

                            <button onClick={(e) => updatePopup(e)}>Change</button>
                        </form>

                        <form className='mt-20'>
                            <div className="input-group" style={{ height: "80px" }}>
                                <label>Banner Title</label>
                                <input defaultValue={popups[2].message} name="title" id="title"></input>
                            </div>

                            <div className="input-group" style={{ height: "80px" }}>
                                <label>Banner Message</label>
                                <textarea defaultValue={popups[3].message} name="banner" id="banner"></textarea>
                            </div>

                            <button onClick={(e) => updateBanner(e)}>Change</button>
                        </form>
                    </div>
                </div>}
                {popups && popups[1].message === "" && <div className="admin-tools-wrapper mt-50">
                    <div className="admin-tools">
                        <h4 className="mt-0 mb-20">Authenticate Xero</h4>

                        <button onClick={() => auth()}>Auth</button>
                    </div>
                </div>}
            </div>
            <div className="admin-wrapper mt-20 mb-50">
                <button onClick={() => navigate('/admin/createProduct')}>Create Product</button>
                <ProductsList data={products} />
            </div>
        </div>
    );
}

export default Admin;