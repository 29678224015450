import { useSelector } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import './CreditNoteDetail.css';
import { useEffect, useState } from 'react';
import CartList from '../../components/CartList/CartList';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PrintHero from '../../components/PrintHero/PrintHero';
import ProductPopup from '../../components/ProductPopup/ProductPopup';

const CreditNoteDetail = () => {
    const old = false;
    const [order, setOrder] = useState(null);
    const [admin, setAdmin] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [creditTotal, setCreditTotal] = useState(0);
    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setAdmin(localStorage.getItem('admin'));
        if (params.id) {
            getOrder(params.id);
        } else {
            navigate('/');
        }
    }, [params])

    const getOrder = (id) => {
        fetch(`${process.env.REACT_APP_API}/api/getOrder/${id}`, {})
        .then(response => {
            if (response.status === 200) {
                response.json().then(data => {
                    setOrder(data);
                    calculateCreditTotal(data.creditNote.items);
                });
            }
        })
    }

    const calculateCreditTotal = (cart) => {
        let total = 0;

        for (let i = 0; i < cart.length; i++) {
            const item = cart[i];
            
            total += item.amount * item.price
        }

        setCreditTotal(total);
    }

    const print = () => {
        setPrinting(true);
        setTimeout(() => {
            window.print();
          }, "1000");
    }

    window.onbeforeprint = (event) => {
        setPrinting(true);
    };
    
    window.onafterprint = (event) => {
        setPrinting(false);
    };

    const updatePayment = (e) => {
        e.preventDefault();

        fetch(`${process.env.REACT_APP_API}/api/changeCreditPayment/${order.creditNote._id}`, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify({
                surcharge: document.getElementById('surcharge').value,
                shipping: document.getElementById('shipping').value
            })
        })
        .then(response => {
            if (response.status === 200) {
                toast.success('Changed payment details!');
                getOrder(params.id);
            } else if (response.status === 500) {
                toast.error('Server error.');
            }
        });
    }


    return (
        <div className={printing ? 'order-detail order-detail-printing mb-50' : 'order-detail mb-50'}>
            {printing ? <PrintHero /> : <Navbar />}
            {isModalOpen && <ProductPopup setIsModalOpen={setIsModalOpen} updateProduct={updateProduct} />}
            <div className={printing ? 'order-detail-toolbar' : 'order-detail-toolbar'}>
                {order && admin == "true" && <h1 className={printing && 'mt-5 mb-0'}>Credit Note #{order.creditNote.creditNoteNumber}</h1>}
                {order && <div style={{ display: 'flex', alignItems: 'center' }} className="mt-20">
                    {!printing && admin == "true" && <button onClick={() => print()} style={{ margin: '0px 0px 0px 10px' }}>Print</button>}
                    {!printing && <button onClick={() => navigate(`/invoice/${params.id}`)} style={{ margin: '0px 0px 0px 10px' }}>Invoice</button>}
                    {!printing && admin == "false" && order.invoice.status === "UNPAID" && <button onClick={() => pay()} style={{ margin: '0px 0px 0px 10px' }}>Pay</button>}
                </div>}
            </div>

            <div className={printing ? 'order-detail-toolbar-info mt-0 noshadow' : 'order-detail-toolbar-info mt-50'}>
                {order && 
                    <div className="input-group">
                        <label>Credit Note</label>
                        <p>{order.creditNote.creditNoteNumber}</p>
                    </div>
                }
                {order && 
                    <div className="input-group">
                        <label>Credit Note Date</label>
                        <p>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(order.creditNote.date))}</p>
                    </div>
                }
                {order && 
                    <div className="input-group">
                        <label>Credit Note Total</label>
                        <p>- €{(creditTotal + order.creditNote.shipping + order.creditNote.surcharge).toFixed(2)}</p>
                    </div>
                }
            </div>

            <div className={`${printing ? 'order-detail-wrapper-printing': 'order-detail-wrapper'}`}>
                <div className={printing ? 'cartlist-printing' : ''}>
                    {order && <CartList data={order.cart.filter(x => order.creditNote.items.filter(y => y.SKU === x.id).length > 0).map(x => {return {...x, amount: order.creditNote.items.filter(y => y.SKU === x.id)[0].amount}})} showcase={true} printing={printing} />}
                </div>

                {<div className={`${printing ? 'order-detail-price-wrapper-printing ' : 'order-detail-price-wrapper'}`}>
                    <div className={`order-detail-price mt-20 ${printing && 'noshadow'}`}>
                        {order && <form>
                            <h4 className={`mt-0 ${printing ? 'mb-10 mt-10' : 'mb-20'}`}>Credit Details</h4>

                            <div className={printing && 'order-detail-price-printing'}>
                                <div>
                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Credit Total</label>
                                        <p>- €{creditTotal.toFixed(2)}</p>
                                    </div>

                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Surcharge Total</label>
                                        <p>- € <input defaultValue={order.creditNote.surcharge?.toFixed(2)} id="surcharge" name="surcharge" style={{ width: '50px' }} /></p>
                                    </div>

                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Shipping Total</label>
                                        <p>- € <input defaultValue={order.creditNote.shipping?.toFixed(2)} id="shipping" name="shipping" style={{ width: '50px' }} /></p>
                                    </div>
                                </div>

                                <div>
                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>VAT (18%)</label>
                                        <p>- €{((creditTotal + order.creditNote.surcharge + order.creditNote.shipping) / 1.18 * 0.18).toFixed(2)}</p>
                                    </div>

                                    <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                        <label>Combined Total</label>
                                        <p>- €{(creditTotal + order.creditNote.shipping + order.creditNote.surcharge).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>

                            {order.method && order.method != 'vivaWallet' && <div className="input-group">
                                <label>Method</label>
                                <p>{order.method}</p>
                            </div>}

                            {!printing && <button onClick={updatePayment}>Change</button>}
                        </form>}
                    </div>
                    <div className={`order-detail-price ${printing ? 'noshadow' : 'mt-20'}`}>
                        {order && <form>
                            <h4 className={`mt-0 ${printing ? 'mb-10' : 'mb-20'}`}>Billing Details</h4>

                            <div className={printing && 'order-detail-price-printing'}>
                                <div>
                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Full Name</label>
                                <input defaultValue={old ? order.owner?.name : order.name} id="name" name="name" />
                            </div>

                            {order.company && <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Company Name</label>
                                <input defaultValue={old ? order.owner?.company : order.company} id="company" name="company" />
                            </div>}

                            {order.VAT && <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>VAT NO</label>
                                <input defaultValue={old ? order.owner?.VAT : order.VAT} id="VAT" name="VAT" />
                            </div>}

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Street Address</label>
                                <input defaultValue={old ? order.owner?.street : order.street} id="street" name="street" />
                            </div>
                            </div>
                            <div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Town / City</label>
                                <input defaultValue={old ? order.owner?.city : order.city} id="city" name="city" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Post Code</label>
                                <input defaultValue={old ? order.owner?.postCode : order.postCode} id="postCode" name="postCode" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Phone</label>
                                <input defaultValue={old ? order.owner?.phone : order.phone} id="phone" name="phone" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Email</label>
                                <input defaultValue={old ? order.owner?.email : order.email} id="email" name="email" />
                            </div>
                            </div>
                            </div>
                        </form>}
                    </div>
                    <div className={`order-detail-price ${printing ? 'noshadow' : 'mt-20'}`}>
                        {order && <form>
                            <h4 className={`mt-0 ${printing ? 'mb-10 mt-10' : 'mb-20'}`}>Delivery Details</h4>

                            <div className={printing && 'order-detail-price-printing'}>
                                <div>
                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Full Name</label>
                                <input defaultValue={order.deliveryname || order.deliveryName} id="deliveryname" name="deliveryname" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Company Name</label>
                                <input defaultValue={order.deliverycompany || order.deliveryCompany} id="deliverycompany" name="deliverycompany" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>VAT NO</label>
                                <input defaultValue={order.deliveryVAT || order.deliveryVAT} id="deliveryVAT" name="deliveryVAT" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Street Address</label>
                                <input defaultValue={order.deliverystreet || order.deliveryStreet} id="deliverystreet" name="deliverystreet" />
                            </div>
                            </div>
                            <div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Town / City</label>
                                <input defaultValue={order.deliverycity || order.deliveryCity} id="deliverycity" name="deliverycity" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Post Code</label>
                                <input defaultValue={order.deliverypostCode || order.deliveryPostCode} id="deliverypostCode" name="deliverypostCode" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Phone</label>
                                <input defaultValue={order.deliveryphone || order.deliveryPhone} id="deliveryphone" name="deliveryphone" />
                            </div>

                            <div className={`${printing ? 'input-group-printing' : 'input-group'}`}>
                                <label>Email</label>
                                <input defaultValue={order.deliveryemail || order.deliveryEmail} id="deliveryemail" name="deliveryemail" />
                            </div>
                            </div>
                            </div>
                        </form>}
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default CreditNoteDetail;