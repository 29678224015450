import { useEffect } from 'react';
import './PaymentOptions.css';
import React, { useState } from 'react';

const PaymentOptions = ({ setOption }) => {
  const paymentOptions = [
    { value: 'vivaWallet', label: 'Viva Wallet', info: `<p><img height='50px' src='../../../payments.png' alt='visa mastercard'></img></p>` },
    { value: 'cash', label: 'Cash', info: `<p>Your order will only become active once the cash has been received and checked.*<br />
    Payment by cash at our offices. Please bring your cash payment to the following address:<br />
    SICILY SHOPPING TO MALTA LTD<br />
    TRIQ BURMARRAD MIDLANDS MICRO ENTERPRISE PARK WAREHOUSE E 13<br />
    SPB9060 BURMARRAD</p>` },
    { value: 'cheque', label: 'Cheque', info: `<p>Send a cheque in the name of Sicily Shopping to Malta to SICILY SHOPPING TO MALTA LTD, TRIQ BURMARRAD MIDLANDS MICRO ENTERPRISE PARK WAREHOUSE E 13, IN-NAXXAR NXR 6345</p>` },
    { value: 'bankTransfer', label: 'Bank Deposit', info: `<p>Please send your bank transfer to the following bank details:<br />
    Viva Wallet (Viva Payment Services)<br />
    IBAN GR4970100003930819024694448<br />
    BIC VPAYMTM2 DETAILS Bank ID VPAY BRANCH ID 45001 ACCOUNT NO.393081902469444841</p>` },
  ];

  const [selectedOption, setSelectedOption] = useState(undefined);

  useEffect(() => {
    handleOptionChange(paymentOptions[0].value)
  }, []);

  const handleOptionChange = (option) => {
    setSelectedOption(selectedOption === option ? null : option);
    setOption(option);
  };

  return (
    <div className="payment-options-container">
      <p>SELECT PAYMENT OPTION:</p>
      <div className="options-container">
        {paymentOptions.map((option) => (
          <div key={option.value} className="option">
            <input
              type="radio"
              id={option.value}
              name="paymentOption"
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleOptionChange(option.value)}
            />
            <label htmlFor={option.value}>{option.label}</label>
            <div className={`info ${selectedOption === option.value ? 'info-expanded' : ''}`} dangerouslySetInnerHTML={{ __html: option.info }}>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentOptions;
